import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../style/ProfileCompletionForm.module.css'; // Assuming CSS module
import { Helmet } from 'react-helmet';

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = useCallback((e) => setValue(e.target.value), []);
  return [value, handleChange];
};

const SelectInput = ({ name, value, onChange, options }) => (
  <select name={name} value={value} onChange={onChange} required>
    {options.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

const TextAreaInput = ({ name, value, onChange }) => (
  <textarea name={name} value={value} onChange={onChange} required  placeholder="Your polymer science expertise (e.g., synthesis, materials, chemistry)" />
);

const ProfileCompletionForm = React.memo(() => {
  const navigate = useNavigate();
  const [role, handleRoleChange] = useFormInput('');
  const [expertiseInPolymers, handleExpertiseChange] = useFormInput('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include credentials to send cookies
        body: JSON.stringify({ role, expertiseInPolymers }),
      });
     if (response.ok) {
        navigate('/dashboard'); // Navigate to dashboard upon successful profile update
      } else {
        // Handle server errors
        console.error('Failed to save profile');
      }
    } catch (error) {
      // Handle network errors
      console.error('Error submitting profile:', error);
    }
  };
  

  const roleOptions = [
    { value: '', label: 'Select Your Role' },
    { value: 'Student', label: 'Student' },
    { value: 'Professional', label: 'Professional' },
    { value: 'Researcher', label: 'Researcher' },
    { value: 'Professor', label: 'Professor' },
    { value: 'Technician', label: 'Technician' },
    { value: 'Engineer', label: 'Engineer' },
    { value: 'Scientist', label: 'Scientist' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Other', label: 'Other' },
  ];

  return (
    <div className={styles.profileCompletionForm}>
      <Helmet>
        <title>Complete Your Profile - Polymer Connect</title>
        <meta name="description" content="Complete your profile on Polymer Connect to start connecting with polymer science professionals and enthusiasts." />
      </Helmet>
      <h2>Welcome to the Polymer Science Community!</h2>
      <p>Please complete your profile to connect with fellow enthusiasts and experts.</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="role">Your Role in Polymer Science:</label>
          <SelectInput
            name="role"
            value={role}
            onChange={handleRoleChange}
            options={roleOptions}
          />
        </div>
        <div>
          <label htmlFor="expertiseInPolymers">Your Expertise in Polymers:</label>
          <TextAreaInput
            name="expertiseInPolymers"
            value={expertiseInPolymers}
            onChange={handleExpertiseChange}
          />
        </div>
        <button type="submit">Complete Profile</button>
      </form>
    </div>
  );
});

export default ProfileCompletionForm;
