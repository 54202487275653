import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../style/CreatePost.module.css'; 
import { toast } from 'react-toastify';
import { useAuth } from '../pages/AuthContext';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [fileInfo, setFileInfo] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticated, isEmailVerified } = useAuth();
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        setIsCategoriesLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('Failed to load categories.');
        } finally {
            setIsCategoriesLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isAuthenticated) {
            toast.info('Please sign up or log in to create a post.', { toastId: 'signup-login-info' });
            navigate('/register');
            return; // Prevent form submission
        }
        
        if (!isEmailVerified) {
            toast.warn('Please verify your email to create a post.', { toastId: 'verify-email-warning' });
            return; // Prevent form submission
        }

        const sanitizedTitle = DOMPurify.sanitize(title);
        const sanitizedContent = DOMPurify.sanitize(content);

        setIsLoading(true);
        const formData = new FormData();
        formData.append('title', sanitizedTitle);
        formData.append('content', sanitizedContent);
        formData.append('category_id', selectedCategory);
        if (file) formData.append('file', file);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/threads`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            });
            toast.success('Post created successfully!');
            navigate('/forum');
            resetForm();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setTitle('');
        setContent('');
        setSelectedCategory('');
        setFile(null);
        setFilePreview(null);
        setFileInfo('');
    };

    const handleError = (error) => {
        let errorMessage = 'Failed to create post';
        if (error.response && error.response.data) {
            errorMessage = error.response.data.message || errorMessage;
        }
        console.error('Error creating post:', error);
        toast.error(errorMessage);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;
        if (selectedFile.type.startsWith('image/')) {
            resizeImage(selectedFile, 800, 600);
        } else {
            setFile(selectedFile);
            setFilePreview(null);
            updateFileInfo(selectedFile);
        }
    };

    const updateFileInfo = (file) => {
        setFileInfo(`Name: ${file.name}, Size: ${(file.size / 1024).toFixed(2)} KB`);
    };

    const resizeImage = (file, maxWidth, maxHeight) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = document.createElement('img');
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let { width, height } = getDimensions(img.width, img.height, maxWidth, maxHeight);

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: Date.now(),
                    });
                    setFile(resizedFile);
                    setFilePreview(URL.createObjectURL(resizedFile));
                    updateFileInfo(file);
                }, file.type);
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    };

    const getDimensions = (width, height, maxWidth, maxHeight) => {
        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }
        return { width, height };
    };
    
    return (
        <div className={styles.container}>
             <Helmet>
                <title>Create New Post - Polymer Connect</title>
                <meta name="description" content="Share your insights, questions, or latest discoveries with the Polymer Connect community by creating a new post." />
            </Helmet>
            <h2>Create New Post</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div>
                    <label htmlFor="postTitle" className={styles.label}>Title:</label>
                    <input id="postTitle" type="text" value={title} onChange={(e) => setTitle(e.target.value)} className={styles.input} required />
                </div>
                <div>
                    <label htmlFor="postContent" className={styles.label}>Content:</label>
                    <textarea id="postContent" value={content} onChange={(e) => setContent(e.target.value)} placeholder="Add your content here" className={styles.textarea} required></textarea>
                </div>
                <div>
                <label htmlFor="postCategory" className={styles.label}>Category:</label>
                {isCategoriesLoading ? (
                    <p>Loading categories...</p> // Placeholder text or loading spinner
                ) : (
                    <select id="postCategory" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className={styles.select} required>
                        <option value="">Select a Category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                )}
                </div>
                <div>
                    <label htmlFor="postFile" className={styles.label}>File (Image or Document):</label>
                    <input id="postFile" type="file" onChange={handleFileChange} className={styles.fileInput} />
                    {filePreview && (
                        <div className={styles.previewContainer}>
                            <img src={filePreview} alt="Preview" className={styles.previewImage} />
                            <p>{fileInfo}</p>
                        </div>
                    )}
                </div>
                <button type="submit" className={styles.button} disabled={isLoading}>{isLoading ? 'Creating...' : 'Submit Post'}</button>
            </form>
        </div>
    );
};


export default CreatePost;
