import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Ensure you import CSS for react-toastify
import styles from '../style/ForgotPassword.module.css';
import { Helmet } from 'react-helmet';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Adjust the URL to your API endpoint for forgot password
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Default to localhost if env variable is not set
      await axios.post(`${apiUrl}/api/request-reset-password`, { email });
      toast.success('Please check your email for password reset instructions.');
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div className={styles.forgotPasswordContainer}>
       <Helmet>
        <title>Forgot Password - Polymer Connect</title>
        <meta name="description" content="Forgot your password? Enter your email to receive password reset instructions." />
      </Helmet>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit} className={styles.forgotPasswordForm}>
        <div className={styles.inputGroup}>
          <label htmlFor="email" className={styles.label}>Email:</label>
          <input
            type="email"
            id="email"
            className={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </div>
        <button type="submit" className={styles.button}>Send Reset Link</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
