import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPaperPlane } from 'react-icons/fa';
import styles from '../style/ConnectButton.module.css';
import { useAuth } from '../pages/AuthContext';
import { toast } from 'react-toastify';

const ConnectButton = ({ profileUserId }) => {
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { isAuthenticated, isEmailVerified } = useAuth();

  useEffect(() => {
    // Function to detect click outside the form
    const handleClickOutside = (event) => {
      if (showForm && !event.target.closest(`.${styles.messageForm}`)) {
        setShowForm(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showForm]);

  const openForm = () => {
    if (!isAuthenticated) {
      toast.info('Please sign up or log in to send message request.', { toastId: 'signup-login-info' });
      return; // Prevent form submission
  }
  if (!isEmailVerified) {
      toast.warn('Please verify your email to send message request.', { toastId: 'verify-email-warning' });
      return; // Prevent form submission
  }
    setShowForm(true);
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    console.log("Sending message request to:", profileUserId, "Message:", message);

    axios.post(`${process.env.REACT_APP_API_URL}/api/message-requests`, {
      receiverId: profileUserId,
      requestMessage: message
    }, { withCredentials: true })
    .then(response => {
      setRequestSent(true);
      setShowForm(false);
      setLoading(false);
    })
    .catch(error => {
      setError('Failed to send request');
      setLoading(false);
    });
  };

  const cancelRequest = () => {
    setShowForm(false);
    setMessage('');
  };

  return (
    <div>
      {error && <p className={styles.errorMessage}>{error}</p>}
      {!requestSent && !showForm && (
        <button onClick={openForm} className={styles.connectButton}>
          <FaPaperPlane /> Send Message Request
        </button>
      )}
      {showForm && (
        <div className={styles.formContainer}>
          <form onSubmit={sendRequest} className={styles.messageForm}>
            <textarea
              placeholder="Hi there! I'd like to connect with you because..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <div className={styles.formActions}>
              <button type="submit" className={styles.sendButton} disabled={loading}>
                {loading ? 'Sending...' : 'Send'}
              </button>
              <button type="button" className={styles.cancelButton} onClick={cancelRequest}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
      {requestSent && <p>Request Sent</p>}
    </div>
  );
};

export default ConnectButton;
