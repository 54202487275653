import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../style/Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom'; // Use Link instead of <a> for internal navigation
import { AuthContext } from '../pages/AuthContext';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, setUserId } = useContext(AuthContext);

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, data, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setIsAuthenticated(true);
        setUserId(response.data.userId);
        toast.success('Login successful!');
        navigate('/dashboard');
      } else {
        toast.error('Login failed: Please check your credentials');
      }
    } catch (error) {
      toast.error(`An error occurred during login: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Polymer Connect</title>
        <meta name="description" content="Log in to your Polymer Connect account to connect with polymer science professionals and enthusiasts from around the world." />
        <meta name="keywords" content="polymer science, login, community, connect, networking, polymer" />
      </Helmet>

      <h2>Login to Your Account</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`input-group ${errors.username ? 'error' : ''}`}>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            {...register('username')}
            aria-label="Username"
            placeholder="Enter your username"
          />
          {errors.username && <span className="error-message">{errors.username.message}</span>}
        </div>
        <div className={`input-group ${errors.password ? 'error' : ''}`}>
          <label htmlFor="password">Password:</label>
          <div className="password-input">
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              {...register('password')}
              aria-label="Password"
              placeholder="Enter your password"
            />
            <span className="password-toggle" onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {errors.password && <span className="error-message">{errors.password.message}</span>}
          <div className="forgot-password-link">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </div>
        <button type="submit" disabled={isSubmitting} className="login-btn">
          {isSubmitting ? 'Logging in...' : 'Login'}
        </button>
        <div className="register-link">
          Don't have an account? <Link to="/register">Sign up here</Link>.
        </div>
      </form>
    </div>
  );
};

export default Login;
