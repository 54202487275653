import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Home.css';
import BackToTopButton from '../components/BackToTopButton';
import ThreadsList from '../components/ThreadsList';
import CategoryCards from './CategoryCards'; 
import PolymerScienceNews from './PolymerScienceNews';
import BlogList from './BlogList';

function Home() {
    const navigate = useNavigate();
    
    useEffect(() => {
        // Dynamically insert the Google Analytics script
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y1SD1H1VQX';
        document.head.appendChild(scriptTag);

        // Initialize Google Analytics after the script is loaded
        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-Y1SD1H1VQX');
    }, []);

    const handleNavigation = (path) => () => navigate(path);

    return (
        <div className="home container-fluid">
            <Helmet>
                <title>Polymer Connect - Polymer Science Community</title>
                <meta name="description" content="Welcome to Polymer Connect, the global Polymer Science Community. Join us to interact, be inspired, and drive innovation in polymer science." />
                <meta name="keywords" content="polymer science, polymer community, polymer discussions, polymer innovation" />
            </Helmet>
            <header className="text-center mt-4 mb-5">
                <h1>Welcome to the Polymer Connect</h1>
                <p className="bold-tagline">Join the global Polymer Science Community, interact with your peers, be inspired, and drive innovation.</p>
            </header>

            <div className="text-center my-4">
                <button className="btn btn-primary btn-join-community" onClick={handleNavigation('/register')}>
                    Join the Community
                </button>
            </div>

            {/* <CategoryCards /> */}

            <div className="row justify-content-center">
                <div className="col-12 col-lg-8 mb-4">
                    <h2 className="text-center mb-4">Latest Discussions</h2>
                    <ThreadsList />
                </div>
            </div>

            {/* Display the BlogList with a "See More" button underneath */}
            <BlogList limit={3} />
            <div className="text-center mt-4">
                <button className="btn btn-primary btn-sm btn-view-all " onClick={handleNavigation('/blog')}>
                View All Blogs
                </button>
            </div>

             <div className="row justify-content-center mt-5">
                <div className="col-12">
                    <h2 className="text-center mb-4">Polymer Science News</h2>
                    <PolymerScienceNews />
                </div>
            </div> 
            <BackToTopButton />
        </div>
    );
}

export default Home;
