import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../style/JobPostings.module.css';

const JobPostings = () => {
  const [jobPostings, setJobPostings] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchJobPostings = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);
        setJobPostings(response.data);
      } catch (error) {
        console.error('Error fetching job postings:', error);
        setError('Failed to load job postings.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchJobPostings();
  }, []);
  
  const handleJobSelect = (jobId) => {
    navigate(`/job-details/${jobId}`);
  };
  
  if (isLoading) return <div>Loading job postings...</div>;
  if (error) return <div>{error}</div>;
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Jobs</h1>
      </div>
      <div className={styles.jobList}>
        {jobPostings.map((job) => (
          <div key={job.job_id} className={styles.jobCard} onClick={() => handleJobSelect(job.job_id)}>
            {job.company_logo && (
              <img 
                src={job.company_logo}
                alt={`${job.company_name} Logo`} 
                className={styles.companyLogo} 
              />
            )}
            <div className={styles.jobInfo}>
              <h2>{job.title}</h2>
              <p><strong>Company:</strong> {job.company_name || 'Unknown'}</p>
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Type:</strong> {job.type}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobPostings;
