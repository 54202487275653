// ResetPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from '../style/ResetPassword.module.css';
import { Helmet } from 'react-helmet';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams(); // Assuming you're using react-router-dom for routing
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords don't match.");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      await axios.post(`${apiUrl}/api/reset-password`, { token, password });
      toast.success('Your password has been reset successfully.');
      navigate('/login'); // Redirect user to login page
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Reset Password - Polymer Connect</title>
        <meta name="description" content="Reset your password for Polymer Connect. Enter your new password to regain access to your account." />
      </Helmet>
      <h2 className={styles.formTitle}>Reset Password</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div>
          <label className={styles.label}>New Password:</label>
          <input
            type="password"
            className={styles.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label className={styles.label}>Confirm New Password:</label>
          <input
            type="password"
            className={styles.input}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
