import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../style/Thread.module.css';
import { images } from '../utils/images';
import {useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../pages/AuthContext';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';


const Thread = () => {
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const { categoryId } = useParams();
    const { userId, isAdmin } = useAuth();

    useEffect(() => {
        setLoading(true); // Start loading before fetching data
        const loadThreads = async () => {
            try {
                const apiUrl = categoryId 
                    ? `${process.env.REACT_APP_API_URL}/api/threads?categoryId=${categoryId}&page=${page}&limit=10`
                    : `${process.env.REACT_APP_API_URL}/api/threads?page=${page}&limit=10`;

                const response = await axios.get(apiUrl);
                const fetchedThreads = response.data.threads;
                const hasMoreData = response.data.hasMore;
              
                if (page === 1) {
                    setThreads(fetchedThreads);
                } else {
                    const newThreads = fetchedThreads.filter(thread => !threads.some(t => t.id === thread.id));
                    setThreads(prev => [...prev, ...newThreads]);
                }
                setHasMore(hasMoreData);
            } catch (error) {
                console.error('Error fetching threads');
            } finally {
                setLoading(false); // End loading after fetching data
            }
        };

        loadThreads();
    }, [page, categoryId]);
    
    
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || !hasMore) return;
            setPage(prevPage => prevPage + 1);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore]);

    useEffect(() => {
        if (!loading) {
            const scrollPosition = sessionStorage.getItem('scrollPosition');
            if (scrollPosition) {
                window.scrollTo(0, parseInt(scrollPosition, 10));
                sessionStorage.removeItem('scrollPosition'); // Clear after restoring to prevent affecting future navigations
            }
        }
    }, [loading]);

    const navigateToDetails = (threadId) => {
        console.log('Storing scroll position:', window.scrollY.toString());
        sessionStorage.setItem('scrollPosition', window.scrollY.toString());
        navigate(`/thread/${threadId}`);
    };
    

    const navigateToProfile = (userId) => {
        console.log('Storing scroll position:', window.scrollY.toString());
        sessionStorage.setItem('scrollPosition', window.scrollY.toString());
        navigate(`/userprofile/${userId}`);
    };
    const deleteThread = async (threadId) => {
        // Display confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to delete this Post?");
        
        // Proceed with deletion if confirmed
        if (isConfirmed) {
            try {
                
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/threads/${threadId}`, {
                    withCredentials: true
                });
                setThreads(threads.filter(thread => thread.id !== threadId)); // Remove the deleted thread from state
                toast.success('Post deleted successfully.'); // Show success toast
            } catch (error) {
                console.error('Error deleting Post:', error);
                toast.error('Failed to delete Post.'); // Show error toast if deletion fails
            }
        }
    };

    const renderFileAttachment = (filePath) => {
        if (!filePath) return null;
    
        // Extract the file extension to determine the file type
        const fileName = filePath.split('/').pop();
        const fileType = fileName.split('.').pop().toLowerCase();
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileType);
    
        if (isImage) {
            // For images, construct the URL with transformation parameters
            // Example transformation: resize width to 150 pixels while maintaining aspect ratio
            const transformedUrl = filePath.replace('/upload/', '/upload/w_150,c_scale/');
    
            return (
                <img src={transformedUrl} alt="Attachment" className={styles.attachmentImage} loading="lazy"/>
            );
        } else {
            // For documents, use a generic icon and provide a link to the document
            let fileIcon = images.doc; // Default to a generic document icon
            if (fileType === 'pdf') {
                fileIcon = images.pdf;
            }
    
            return (
                <div className={styles.fileAttachment}>
                    <a href={filePath} target="_blank" rel="noopener noreferrer">
                        {fileIcon ? (
                            <div className={styles.fileIcon}>
                                <img src={fileIcon} alt="Document" loading="lazy" />
                                <span>{fileName}</span>
                            </div>
                        ) : (
                            <span>{fileName}</span> // Fallback text if no icon is available
                        )}
                    </a>
                </div>
            );
        }
    };
    

    return (
        <div>
            <Helmet>
                <title>Forum - Polymer Connect</title>
                <meta name="description" content="Explore the latest discussions and threads on Polymer Science topics. Join the conversation today." />
                <meta name="keywords" content="polymer science, discussions, threads, polymer community" />
            </Helmet>

            {threads.map(thread => (
                <div key={thread.id} className={styles.threadItem}>
                    <div className={styles.categoryTag}>{thread.category_name}</div>
                    <div className={styles.contentWrapper} onClick={() => navigateToDetails(thread.id)}>
                        <div className={styles.userInfo} onClick={(e) => e.stopPropagation()}>
                            <img
                                src={thread.profile_photo_url}
                                alt="Profile"
                                className={styles.profilePic}
                                onClick={() => navigateToProfile(thread.user_id)}
                            />
                            <p className={styles.username}onClick={() => navigateToProfile(thread.user_id)}>{thread.username}</p>
                            {(thread.user_id === userId || isAdmin) && (
                                
                                <FaTrash
                                    className={styles.deleteIcon}
                                    data-testid={`delete-button-${thread.id}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteThread(thread.id);
                                    }}
                                    title="Delete thread"
                                />
                            )}
                        </div>
                        <div className={styles.threadContent}>
                            <h3 className={styles.title}>{thread.title}</h3>
                            <p className={styles.content}>{thread.content}</p>
                            {renderFileAttachment(thread.file_path)}
                            <p className={styles.timestamp}>
                                Posted on {new Date(thread.created_at).toLocaleString()}
                            </p>
                            <p className={styles.replyCount}>Replies: {thread.reply_count}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

};

export default Thread;
