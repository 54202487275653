import React, { useState, useEffect } from 'react';
import VerifiyEmail from './VerifyEmail';
import { useLocation } from 'react-router-dom';

function UserVerificationPage() {
    const location = useLocation();
    const [userEmail, setUserEmail] = useState('');
    console.log("Email in UserVerificationPage:", userEmail);
    useEffect(() => {

        // Extract userEmail from location state
        if (location.state?.email) {
            setUserEmail(location.state.email);
        } else {
            console.log('Email not found in location state');
        }
    }, [location]);



    return (
        <div>
            {userEmail && <VerifiyEmail email={userEmail}  />}
        </div>
    );
}

export default UserVerificationPage;
