import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../style/CategoryCards.module.css';
import { useNavigate } from 'react-router-dom';

const CategoryCards = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/categories`)
            .then(response => {
                setCategories(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    }, []);

    const handleCategoryClick = (categoryId) => {
        navigate(`/category/${categoryId}`);

    };

   

    if (loading) {
        return <p>Loading categories...</p>;
    }

    return (
        <>
            
            <h2 className={styles.categoryHeading}>Explore Different Categories</h2>
            <div className={styles.cardGrid}>
                {categories.map(category => (
                    <div className={styles.categoryCard} key={category.id} onClick={() => handleCategoryClick(category.id)}>
                        <h3 className={styles.categoryName}>{category.name}</h3>
                        <p className={styles.categoryDescription}>{category.description}</p>
                        <span className={styles.threadCount}>{category.threadcount} Posts</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CategoryCards;
