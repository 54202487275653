import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import styles from '../style/JobPostingForm.module.css'; // Ensure this CSS module exists and is properly styled
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const JobPostingForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Extract company ID from URL or state
  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get('companyId');

  const onSubmit = async (data) => {
    const postData = { ...data, company_id: companyId }; // Include the company ID in the submission data
    
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/jobs`, postData, {
        withCredentials: true,
      });
      
      toast.success('Job posted successfully!');
      reset();
      navigate('/'); // Redirect or handle post-submission flow
    } catch (error) {
      console.error('Failed to post job:', error);
      toast.error('Failed to post job.');
    }
    console.log(postData);
  };

  // Define validation rules here
  const validationRules = {
    title: {
      required: "Job title is required.",
    },
    description: {
      required: "Job description is required.",
    },
    qualifications: {
      required: "Qualifications are required.",
    },
    type: {
      required: "Please select a job type.",
    },
    location: {
      required: "Location is required.",
    },
    deadline: {
      required: "Application deadline is required.",
    },
  };

  return (
    <>
     <h1 className={styles.formHeading}>Post a New Job</h1>
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer} noValidate>
      <div className={styles.formGroup}>
        <label htmlFor="title" className={styles.label}>Job Title:</label>
        <input id="title" type="text" {...register('title', validationRules.title)} className={errors.title ? `${styles.input} ${styles.inputError}` : styles.input} />
        {errors.title && <span className={styles.errorMsg}>{errors.title.message}</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="description" className={styles.label}>Job Description:</label>
        <textarea id="description" {...register('description', validationRules.description)} className={errors.description ? `${styles.textarea} ${styles.textareaError}` : styles.textarea}></textarea>
        {errors.description && <span className={styles.errorMsg}>{errors.description.message}</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="qualifications" className={styles.label}>Qualifications:</label>
        <textarea id="qualifications" {...register('qualifications', validationRules.qualifications)} className={errors.qualifications ? `${styles.textarea} ${styles.textareaError}` : styles.textarea}></textarea>
        {errors.qualifications && <span className={styles.errorMsg}>{errors.qualifications.message}</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="type" className={styles.label}>Job Type:</label>
        <select id="type" {...register('type', validationRules.type)} className={errors.type ? `${styles.select} ${styles.selectError}` : styles.select}>
          <option value="">Select a type</option>
          <option value="full-time">Full-time</option>
          <option value="part-time">Part-time</option>
          <option value="contract">Contract</option>
          <option value="internship">Internship</option>
        </select>
        {errors.type && <span className={styles.errorMsg}>{errors.type.message}</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="location" className={styles.label}>Location:</label>
        <input id="location" type="text" {...register('location', validationRules.location)} className={errors.location ? `${styles.input} ${styles.inputError}` : styles.input} />
        {errors.location && <span className={styles.errorMsg}>{errors.location.message}</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="deadline" className={styles.label}>Application Deadline:</label>
        <input type="date" id="deadline" {...register('deadline', validationRules.deadline)} className={errors.deadline ? `${styles.input} ${styles.inputError}` : styles.input} />
        {errors.deadline && <span className={styles.errorMsg}>{errors.deadline.message}</span>}
      </div>
      <div className={styles.formGroup}>
  <label htmlFor="applyLink" className={styles.label}>Apply Link (optional):</label>
  <input id="applyLink" type="url" {...register('applyLink', { pattern: /^(http|https):\/\/[^ "]+$/ })} className={errors.applyLink ? `${styles.input} ${styles.inputError}` : styles.input} />
  {errors.applyLink && <span className={styles.errorMsg}>{"Please enter a valid URL."}</span>}
</div>
<div className={styles.formGroup}>
  <label htmlFor="contactInstructions" className={styles.label}>Contact Instructions (if no apply link):</label>
  <textarea id="contactInstructions" {...register('contactInstructions')} className={styles.textarea}></textarea>
  <p className={styles.helpText}>Provide instructions on how candidates should apply if you're not including an apply link.</p>
</div>


      <button type="submit" className={styles.button}>Post Job</button>
    </form>
    </>
  );
};

export default JobPostingForm;
