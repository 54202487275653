// Forum.js
import React from 'react';
import CategoryCards from './CategoryCards'; // Import the CategoryCards component
import Thread from './Thread'; // Import the Thread component
import styles from '../style/Forum.module.css'; // Assuming you have a corresponding CSS module
import { useNavigate } from 'react-router-dom';


const Forum = () => {
    const navigate = useNavigate();
    const navigateToCreatePost = () => {
        navigate('/create-post');
    };

    return (
        <div className={styles.forumContainer}>
            <header className={styles.forumHeader}>
                <h1>Forum</h1>
            </header>
            <div className={styles.container}>
                <button className={styles.createThreadButton} onClick={navigateToCreatePost}>
                    Create New Post
                </button>
            </div>
            <CategoryCards /> {/* CategoryCards component to display categories */}
            <h2 className={styles.latestPostsHeading}>Latest Posts</h2>
            <div className={styles.threadsWrapper}>
                <Thread /> {/* Thread component to display threads */}
            </div>
            
        </div>
    );
};

export default Forum;
