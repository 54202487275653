import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet
import styles from '../style/BlogDetail.module.css'; // Import your CSS module here

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/blogs/${id}`)
      .then(response => {
        setBlog(response.data);
      })
      .catch(error => {
        console.error('Error fetching blog detail:', error);
      });
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  return (
    <div className={styles.blogContainer}>
      {/* SEO Optimization with react-helmet */}
      <Helmet>
        <title>{blog.title} - Polymer Science Insights</title>
        <meta name="description" content={blog.summary || blog.content.substring(0, 150)} />
        {/* Add additional meta tags for SEO */}
        <meta name="keywords" content={`polymer science, ${blog.keywords || ''}`} />
        {/* Consider adding structured data with application/ld+json for better SEO */}
      </Helmet>
      <h1 className={styles.title}>{blog.title}</h1>
      <p className={styles.content}>{blog.content}</p>
    </div>
  );
};

export default BlogDetail;
