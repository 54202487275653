import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuth } from '../pages/AuthContext';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { isAuthenticated } = useAuth(); // Using the authentication context

  useEffect(() => {
    if (isAuthenticated) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      setSocket(newSocket);
      newSocket.on('connect', () => {
        console.log('Socket connected:', newSocket.id); // For debugging
    });
      return () => newSocket.disconnect();
    } else {
      // Disconnect the socket if the user is not authenticated (logged out)
      if (socket) {
        socket.disconnect();
      }
      // Ensure the socket state is set to null after disconnection
      setSocket(null);
    }
  }, [isAuthenticated]); // Effect depends on the user's authentication status

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
