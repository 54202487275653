// Footer.js
import React from 'react';
import styles from '../style/Footer.module.css'; // Assume you have a corresponding CSS file for styling

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <section className={styles.links}>
          <a href="/about">About Us</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/contact">Contact Us</a>
        </section>
        <section className={styles.socialMedia}>
          <a href="https://twitter.com/TPolymerConnect" target="_blank" rel="noopener noreferrer">X(Twitter)</a>
          {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a> */}
        </section>
        <section className={styles.copyRight}>
          <p>&copy; {new Date().getFullYear()} The Polymer Connect. All rights reserved.</p>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
