import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../style/ThreadDetail.module.css';
import { FaTrash } from 'react-icons/fa';
import { useAuth } from '../pages/AuthContext';
import { toast } from 'react-toastify';
import { images } from '../utils/images';
import { Helmet } from 'react-helmet'; 


const ThreadDetail = () => {
    const [thread, setThread] = useState(null);
    const [replies, setReplies] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [newReply, setNewReply] = useState('');
    const { threadId } = useParams();
    const navigate = useNavigate();
    const { userId, isAdmin,isAuthenticated, isEmailVerified  } = useAuth();

    const fetchReplies = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/thread/${threadId}/replies`, { params: { page, limit: 10 }})
            .then(response => {
                setReplies(response.data.replies);
                if (response.data.replies.length < 10) {
                    setHasMore(false);
                }
            })
            .catch(error => console.error('Error fetching replies:', error));
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/thread/${threadId}/details`)
            .then(response => setThread(response.data.thread))
            .catch(error => console.error('Error fetching thread:', error));
    }, [threadId]);

    useEffect(() => {
        fetchReplies();
    }, [threadId, page]);

    const loadMoreReplies = () => setPage(prevPage => prevPage + 1);

    const postReply = () => {
        if (!isAuthenticated) {
            toast.info('Please sign up or log in to post a reply.', { toastId: 'signup-login-info' });
            return;
        }
        if (!isEmailVerified) {
            toast.warn('Please verify your email to post a reply.', { toastId: 'verify-email-warning' });
            return;
        }
        if (newReply.trim()) {
            axios.post(
                `${process.env.REACT_APP_API_URL}/api/thread/${threadId}/reply`,
                { content: newReply },
                { withCredentials: true }
            )
            .then(response => {
                if (response.status === 200) {
                    setNewReply('');
                    fetchReplies();  // Refresh replies list after posting a new reply
                } else {
                    console.error('Error posting reply: Unexpected status code');
                }
            })
            .catch(error => {
                console.error('Error posting reply:', error);
            });
        } else {
            console.error('Reply is empty');
        }
    };

    const renderProfileImage = (profilePhotoUrl) => {
        if (profilePhotoUrl) {
            return profilePhotoUrl;
        }
        return '/images/default-profile-icon.png'; // Make sure this path is correct and accessible
    };
    const navigateToProfile = (userId) => {
        navigate(`/userprofile/${userId}`);
    };
    const deleteReply = async (replyId) => {
        // Display confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to delete this reply?");
        
        // Proceed with deletion if confirmed
        if (isConfirmed) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/thread/replies/${replyId}`, {
                    withCredentials: true
                });
                // Refresh replies list after deleting a reply
                setReplies(replies.filter(reply => reply.id !== replyId));
                toast.success('Reply deleted successfully.');
            } catch (error) {
                console.error('Error deleting reply:', error);
                toast.error('Failed to delete reply.');
            }
        }
    };
    const renderFileAttachment = (filePath) => {
        if (!filePath) return null;
        
        // Determine if the file is an image or a non-image based on file extension
        const isImage = filePath.match(/\.(jpg|jpeg|png|gif)$/i);
        const fileName = filePath.split('/').pop();
        
        // Use Cloudinary's URL for the file; adjust for non-image files
        let fileUrl = filePath;
        if (!isImage) {
            // Only adjust the URL if it doesn't already include '/raw/upload/'
            if (!filePath.includes('/raw/upload/')) {
                fileUrl = filePath.replace('/upload/', '/raw/upload/');
            }
        }
        
        if (isImage) {
            return (
                <img src={fileUrl} alt="Attachment" className={styles.attachmentImage} />
            );
        } else {
            // Choose an icon based on file type, if desired
            let fileIcon;
            if (filePath.match(/\.pdf$/i)) {
                fileIcon = images.pdf; // Ensure this icon is defined or imported
            } else if (filePath.match(/\.(doc|docx)$/i)) {
                fileIcon = images.doc; // Ensure this icon is defined or imported
            }
    
            return (
                <div className={styles.fileAttachment}>
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                        {fileIcon ? (
                            <div className={styles.fileIcon}>
                                <img src={fileIcon} alt="Document" />
                                <span>{fileName}</span>
                            </div>
                        ) : (
                            <span>{fileName}</span> // For other file types or when an icon is not available
                        )}
                    </a>
                </div>
            );
        }
    };
    

    if (!thread) return <p>Loading...</p>;

    return (
        <div className={styles.threadDetailContainer} >
             <Helmet>
                <title>{thread?.title ? `${thread.title} - Polymer Connect` : 'Thread Detail - Polymer Connect'}</title>
                <meta name="description" content={`Learn more about "${thread?.title}" in this detailed discussion on Polymer Connect.`} />
            </Helmet>
            <div className={styles.threadUserInfo}>
        <div onClick={() => navigateToProfile(thread.user_id)} className={styles.clickableArea}>
        <img src={renderProfileImage(thread?.profile_photo_url)} alt='' className={styles.profilePic} />
            <p className={styles.username}>{thread?.username ?? 'Unknown User'}</p>
        </div>
    </div>
            <h2 className={styles.threadTitle}>{thread?.title}</h2>
            <p className={styles.threadContent}>{thread?.content}</p>
            {thread?.file_path && renderFileAttachment(thread.file_path)}
            <p className={styles.threadCategory}>{thread?.category_name}</p>
            <div className={styles.replyForm}>
                <textarea 
                    placeholder="Write a comment..." 
                    value={newReply}
                    onChange={(e) => setNewReply(e.target.value)}
                />
                <button onClick={postReply} className={styles.replyButton}>Post Reply</button>
            </div>
            <div className={styles.repliesContainer}>
            {replies.map(reply => (
    <div key={reply.id} className={styles.replyItem}>
        <div className={styles.replyUserInfo}>
            <div onClick={() => navigateToProfile(reply.user_id)} className={styles.clickableArea}>
                <img src={renderProfileImage(reply.profile_photo_url, reply.user_id)} alt="" className={styles.replyProfilePic} />
                <p className={styles.replyUsername}>{reply.username ?? 'You'}</p>
            </div>
        </div>
        <p className={styles.replyContent}>{reply.content}</p>
        <p className={styles.replyTimestamp}>{reply.created_at}</p>
        {/* Adjusted condition to allow deletion for reply authors or admins */}
        {(reply.user_id === userId || isAdmin) && (
            <FaTrash
                className={styles.deleteIcon}
                onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering navigation to profile
                    deleteReply(reply.id);
                }}
                title="Delete reply"
            />
        )}
    </div>
))}
</div>
            {hasMore && <button onClick={loadMoreReplies}>Load More Replies</button>}
        </div>
    );
};

export default ThreadDetail;
