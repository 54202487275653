import React from 'react';
import styles from '../style/IntellectualPropertyPolicy.module.css'; // Ensure the CSS module exists and is correctly linked

const IntellectualPropertyRightsAgreement = () => {
  return (
    <div className={styles.intellectualPropertyPolicyContainer}>
      <h1 className={styles.intellectualPropertyTitle}>Intellectual Property Rights Agreement for Polymer Connect</h1>
      
      <h2 className={styles.intellectualPropertyHeading}>1. Introduction</h2>
      <p className={styles.intellectualPropertyParagraph}>This Intellectual Property Rights Agreement ("Agreement") is made between the contributor ("Contributor") and Polymer Connect, ("Website"). This Agreement governs the submission of projects, proposals, research, data, and any other materials ("Submission") to the Website. By contributing Submissions, the Contributor agrees to the terms outlined herein.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>2. Ownership and License</h2>
      <h3 className={styles.intellectualPropertyHeading}>2.1 Ownership</h3>
      <p className={styles.intellectualPropertyParagraph}>The Contributor asserts they are the original creator of the Submission and possess all necessary intellectual property rights. Ownership of these rights remains with the Contributor, except as explicitly altered by this Agreement.</p>
      <h3 className={styles.intellectualPropertyHeading}>2.2 Grant of License</h3>
      <p className={styles.intellectualPropertyParagraph}>The Contributor grants the Website a non-exclusive, worldwide, royalty-free license to use, display, publish, translate, modify (subject to below conditions), and distribute the Submission for promoting polymer science and related activities.</p>
      <p className={styles.intellectualPropertyParagraph}><strong className={styles.intellectualPropertyStrong}>Modifications:</strong> The Website may edit the Submission to comply with content guidelines or for clarity, provided that such modifications do not materially alter the original content. The Contributor will be notified of significant changes for approval.</p>
      <p className={styles.intellectualPropertyParagraph}><strong className={styles.intellectualPropertyStrong}>License Scope:</strong> This license allows the Website to sublicense and use the Submission across various platforms and mediums as required for the Website's objectives, except where expressly limited by the Contributor.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>3. Representations and Warranties</h2>
      <p className={styles.intellectualPropertyParagraph}>The Contributor confirms that the Submission is their original work, free from third party intellectual property claims, and contains no libelous or unlawful content. The Website does not warrant the accuracy, reliability, or completeness of any Submission published.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>4. Indemnification</h2>
      <p className={styles.intellectualPropertyParagraph}>The Contributor will indemnify the Website against claims related to intellectual property infringement, data accuracy, or content legality, covering direct damages but excluding consequential or incidental damages.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>5. Modifications to the Submission</h2>
      <p className={styles.intellectualPropertyParagraph}>The Website reserves the right to modify or reject any Submission, provided these changes do not materially distort the Submission's original intent.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>6. Termination</h2>
      <p className={styles.intellectualPropertyParagraph}>This Agreement remains effective until terminated. Upon termination, the Website retains the right to use previously submitted content under the granted license terms.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>7. Governing Law and Dispute Resolution</h2>
      <p className={styles.intellectualPropertyParagraph}>This Agreement is governed by [Jurisdiction] law. Disputes arising under this Agreement will be resolved through arbitration or mediation, as agreed by the Parties.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>8. Miscellaneous</h2>
      <p className={styles.intellectualPropertyParagraph}><strong className={styles.intellectualPropertyStrong}>Amendments and Waiver:</strong> This Agreement can only be amended in writing, signed by both Parties. No waiver of any Agreement provision shall constitute a waiver of any other provision(s).</p>
      <p className={styles.intellectualPropertyParagraph}><strong className={styles.intellectualPropertyStrong}>Digital Signature and Acceptance:</strong> Digital or electronic signatures of this Agreement by the Contributor are considered legally binding and equivalent to handwritten signatures.</p>
      
      <h2 className={styles.intellectualPropertyHeading}>9. Entire Agreement and Acknowledgment of Policies</h2>
      <p className={styles.intellectualPropertyParagraph}>This Agreement constitutes the entire agreement between the Parties, superseding previous understandings. Contributors are advised to review and agree to the Website's privacy policy and content guidelines, which are not superseded by this Agreement.</p>
      <p className={styles.intellectualPropertyParagraph}>By submitting to Polymer Connect, the Contributor acknowledges and agrees to the terms of this Intellectual Property Rights Agreement.</p>
    </div>
  );
};

export default IntellectualPropertyRightsAgreement;
