import React from 'react';
import styles from '../style/PrivacyPolicy.module.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
       <Helmet>
        <title>Privacy Policy - Polymer Connect</title>
        <meta name="description" content="Read Polymer Connect's Privacy Policy to understand how we collect, use, and protect your information as you engage with our community platform." />
      </Helmet>
      <h1 className={styles.title}>Privacy Policy</h1>
      <p className={styles.text}>Effective Date: March 2, 2024</p>
      
      <p className={styles.text}>Thank you for joining the Polymer Connect. We are committed to protecting your privacy and ensuring you have a positive experience on our platform. This Privacy Policy outlines our practices regarding the collection, use, and protection of your information through the forums.</p>
      
      <h2 className={styles.subtitle}>1. Collection of Information</h2>
      <p className={styles.text}>We collect information to provide and improve our services. This includes:</p>
      <ul>
        <li><strong>Registration Information:</strong> We collect your email address at registration. Optionally, you may share your full name, LinkedIn profile, and other professional details.</li>
        <li><strong>Forum Participation:</strong> We collect the content of your posts, comments, and discussions within the forums.</li>
      </ul>

      <h2 className={styles.subtitle}>2. Use of Your Information</h2>
      <p className={styles.text}>We use your information to:</p>
      <ul>
        <li>Facilitate your participation in the forums.</li>
        <li>Enhance forum functionality and user experience.</li>
        <li>Communicate important notices and updates.</li>
        <li>Analyze forum usage to improve our services.</li>
      </ul>

      <h2 className={styles.subtitle}>3. Cookies and Tracking Technologies</h2>
      <p className={styles.text}>We use cookies to remember your preferences and improve your browsing experience. You can manage your cookie settings through your browser.</p>
      
      <h2 className={styles.subtitle}>4. Sharing and Disclosure of Information</h2>
      <p className={styles.text}>Your information is shared only as necessary:</p>
      <ul>
        <li>Forum posts are publicly visible within the community.</li>
        <li>Your information may be disclosed if legally required or to protect our community's safety and rights.</li>
      </ul>
      
      <h2 className={styles.subtitle}>5. Security of Your Information</h2>
      <p className={styles.text}>We implement security measures to protect your data, including encryption and access control. However, no system can be 100% secure, and we cannot guarantee the absolute security of your information.</p>

      <h2 className={styles.subtitle}>6. Your Privacy Rights</h2>
      <p className={styles.text}>You have the right to access, update, or delete your personal information. For such requests, or if you have privacy concerns, please contact us directly.</p>

      <h2 className={styles.subtitle}>7. Protection of Children’s Privacy</h2>
      <p className={styles.text}>Our forums are intended for users over the age of 16. We do not knowingly collect information from children under this age. If we become aware of such collection, we will take steps to delete the information promptly.</p>

      <h2 className={styles.subtitle}>8. Updates to This Policy</h2>
      <p className={styles.text}>We may update this Privacy Policy to reflect changes in our practices. We will notify you of significant changes through the forum or by email.</p>

      <h2 className={styles.subtitle}>9. How to Contact Us</h2>
      <p className={styles.text}>For any questions or concerns regarding this Privacy Policy or our data practices, please email us at polymercommunity@gmail.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
