import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from '../style/EmployersPage.module.css'; 
import { useAuth } from '../pages/AuthContext';
const EmployersPage = () => {
  const { isAuthenticated } = useAuth(); 
  const navigate = useNavigate(); 

  const handleSelectClick = () => {
    if (!isAuthenticated) {
      toast.info("Please sign up or log in to post a job");
      navigate('/register');
    } else {
      navigate('/company-list');
    }
  };

  return (
    <div className={styles.container}>
      <h1>Job Posting Packages for Employers</h1>
      <div className={styles.pricingContainer}>
        {/* Single Job Post Package */}
        <div className={styles.package}>
          <h2>Single Job Post</h2>
          <ul>
            <li>Pinned to the top for 30 days</li>
            <li>Maximum visibility</li>
            <li>Quick candidate attraction</li>
          </ul>
          <p className={styles.price}>$90</p>
            <button className={styles.selectButton}  onClick={handleSelectClick}>Select</button>
        </div>
        
        {/* Bundle Package */}
        <div className={styles.package}>
          <h2>Bundle Package</h2>
          <ul>
            <li>5 job posts</li>
            <li>Active for 3 months</li>
            <li>Cost-effective solution</li>
          </ul>
          <p className={styles.price}>$199</p>
          <button className={styles.selectButton}  onClick={handleSelectClick}>Select</button>
        </div>
      </div>
    </div>
  );
};

export default EmployersPage;
