import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ConnectButton from './ConnectButton';
import styles from '../style/Profile.module.css';
import { FaUserCircle, FaLinkedin, FaResearchgate, FaChalkboardTeacher, FaBook, FaAward } from 'react-icons/fa';
import { useAuth } from '../pages/AuthContext';
import { Helmet } from 'react-helmet';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [requestStatus, setRequestStatus] = useState('');
  const [requestRejectionTime, setRequestRejectionTime] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const { userId: loggedInUserId } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const userProfileId = userId ? parseInt(userId, 10) : loggedInUserId;
    const isOwnProfile = loggedInUserId === userProfileId;

    if (isOwnProfile) {
      navigate('/profile', { replace: true });
      return;
    }

    const fetchProfileData = async () => {
      setDataFetched(false);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile/userprofile/${userProfileId}`, { withCredentials: true });
        setUserData(response.data);
        console.log(response.data)
        if (loggedInUserId !== userProfileId) {
          const statusResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/message-requests/status/${userProfileId}`, { withCredentials: true });
          setRequestStatus(statusResponse.data.status);
          setRequestRejectionTime(statusResponse.data.rejectionTime);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setDataFetched(true);
      }
    };

    fetchProfileData();
  }, [userId, loggedInUserId, navigate]);

  const canSendRequest = () => {
    if (requestStatus === 'rejected' && requestRejectionTime) {
      const rejectionTime = new Date(requestRejectionTime);
      const currentTime = new Date();
      const hoursSinceRejection = Math.abs(currentTime - rejectionTime) / 36e5;
      return hoursSinceRejection >= 24;
    }
    return requestStatus !== 'pending';
  };

  if (!dataFetched) {
    return <p>Loading...</p>;
  }
  const handleNavigateToChat = () => {
    navigate('/chat');
  };

  return (
    <div className={styles.profileContainer}>
       <Helmet>
        <title>User Profile - Polymer Connect</title>
        <meta name="description" content="View user profiles on Polymer Connect. Connect, collaborate, and share insights with professionals in the polymer science community." />
      </Helmet>
      <div className={styles.profileView}>
        <h2>Profile</h2>
        {userData.profile_photo_url ? (
          <img src={userData.profile_photo_url} alt="Profile" className={styles.profileImage} />
        ) : (
          <FaUserCircle className={styles.defaultProfileIcon} size="100" />
        )}
        <div className={styles.infoSection}>
          <h3 className={styles.profileName}>{userData.fullname}</h3>
          {/* User Information */}
          <div className={styles.detailSection}>
            <div className={styles.detailItem}><strong>Username:</strong> {userData.username}</div>
            <div className={styles.detailItem}><strong>Role:</strong> {userData.role || "N/A"}</div>
            <div className={styles.detailItem}><strong>Expertise:</strong> {userData.expertise || "N/A"}</div>
          </div>
          {/* Academic and Professional Details */}
          <div className={styles.detailSection}>
            {userData.academiccredentials && <div className={styles.detailItem}><FaChalkboardTeacher /> <strong>Academic Credentials:</strong> {userData.academiccredentials}</div>}
            {userData.researchpublications && <div className={styles.detailItem}><FaBook /> <strong>Research Publications:</strong> {userData.researchpublications}</div>}
            {userData.professionalAchievements && <div className={styles.detailItem}><FaAward /> <strong>Professional Achievements:</strong> {userData.professionalAchievements}</div>}
          </div>
          {/* Social Links */}
          <div className={styles.socialLinks}>
            {userData.linkedInProfile && <a href={userData.linkedInProfile} target="_blank" rel="noopener noreferrer" className={styles.socialLink}><FaLinkedin /> LinkedIn</a>}
            {userData.researchGateProfile && <a href={userData.researchGateProfile} target="_blank" rel="noopener noreferrer" className={styles.socialLink}><FaResearchgate /> ResearchGate</a>}
          </div>
        </div>
        {loggedInUserId !== userData.id && (
          requestStatus === 'accepted' ? (
            <div>

              <button onClick={handleNavigateToChat} className={styles.chatButton}>Go to Chat</button>
            </div>
          ) : requestStatus === 'pending' || !canSendRequest() ? (
            <p>Request Sent</p>
          ) : (
            <ConnectButton profileUserId={userId} />
          )
        )}
      </div>
    </div>
  );
};

export default UserProfile;
