import axios from 'axios';

const setupCsrfToken = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/api/csrf-token`, { withCredentials: true })
    .then(response => {
      const csrfToken = response.data.csrfToken;
      axios.defaults.headers.common['X-CSRF-Token'] = csrfToken; // Set CSRF token for all requests
    })
    .catch(error => console.error('Error fetching CSRF token:', error));
};

export default setupCsrfToken;