import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../style/ProposalDetail.module.css';
import { useAuth } from '../pages/AuthContext';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

const ProposalDetail = () => {
    const [proposal, setProposal] = useState({});
    const { proposalId } = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, isEmailVerified } = useAuth();
    const { userId, isAdmin } = useAuth();

    useEffect(() => {
        const fetchProposalDetail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/project-proposals/${proposalId}`);
                setProposal(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching proposal detail:', error);
            }
        };

        fetchProposalDetail();
    }, [proposalId]);

    const isProfileComplete = (userProfile) => {
        // Define the required fields based on your criteria
        const requiredFields = [
            'fullname', 
            'role', 
            'expertise', 
            'academicCredentials', 
            'professionalAchievements', 
            'researchPublications', 
            'linkedinProfile', 
            'languageSkills'
        ];

        // Check if all required fields are filled
        return requiredFields.every(field => userProfile[field] && userProfile[field].trim() !== '');
    };

    const handleCollaborate = async () => {
        if (!isAuthenticated) {
            toast.info('Please sign up or log in to Collaborate', { toastId: 'signup-login-info' });
            return; // Prevent form submission
        }
        if (!isEmailVerified) {
            toast.warn('Please verify your email to Collaborate', { toastId: 'verify-email-warning' });
            return; // Prevent form submission
        }
        // Fetch user profile data to check if it's complete
        try {
            const profileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/profile`, { withCredentials: true });
            const userProfile = profileResponse.data;

            if (!isProfileComplete(userProfile)) {
                alert('Please complete your profile with all required fields before collaborating on a project.');
                navigate('/complete-profile'); // Adjust the route as necessary
                return;
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
            alert('Error verifying user profile. Please try again later.');
            return;
        }

        // Proceed with the collaboration request if the profile is complete
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/collaborate`, { projectProposalId: proposalId }, {
                withCredentials: true 
            });
            alert('Collaboration request sent successfully!');
        } catch (error) {
            console.error('Error submitting collaboration request:', error);
            alert('Failed to send collaboration request.');
        }
    };
    const deleteProposal = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this proposal?");
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/project-proposals/${proposalId}`, {
                    withCredentials: true,
                });
                toast.success("Proposal deleted successfully.");
                navigate('/project-page'); // Redirect to the proposals list page
            } catch (error) {
                console.error('Error deleting proposal:', error);
                toast.error("Failed to delete proposal.");
            }
        }
    };

    if (!proposal) return <div>Loading...</div>;
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{proposal.title}</h2>
            <div className={styles.profileSection}>
                {proposal.profile_photo_url && (
                    <img src={`${process.env.REACT_APP_API_URL}/${proposal.profile_photo_url}`} alt="Profile" className={styles.profileImage} />
                )}
                <p className={styles.submittedBy}><strong>Submitted by:</strong> {proposal.username}</p>
            </div>
            <div className={styles.details}>
                <p><strong>Research Area:</strong> {proposal.research_area_name}</p>
                <p><strong>Objectives:</strong> {proposal.objectives}</p>
                <p><strong>Methodology:</strong> {proposal.methodology}</p>
                <p><strong>Required Expertise:</strong> {proposal.expertise_required}</p>
                <p><strong>Equipment Needed:</strong> {proposal.equipment_needed}</p>
                <p><strong>Duration:</strong> {proposal.duration}</p>
                <p><strong>Collaboration Terms:</strong> {proposal.collaboration_terms}</p>
                <p><strong>Funding:</strong> {proposal.funding}</p>
                <p><strong>Submitted On:</strong> {new Date(proposal.created_at).toLocaleDateString()}</p>
                </div>
                {(userId === proposal.user_id || isAdmin) && (
                <button onClick={deleteProposal} className={styles.deleteButton}>
                    <FaTrash /> Delete Proposal
                </button>
            )}
            {/* Only show the Collaborate button if the user is not the owner */}
            {(userId !== proposal.user_id) && (
                <button className={styles.button} onClick={() => toast.info('Collaboration feature coming soon!')}>
                    Collaborate
                </button>
            )}

            
        </div>
    );
};

export default ProposalDetail;
