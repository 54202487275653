import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../style/Register.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import 'react-toastify';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../pages/AuthContext';
import { Helmet } from 'react-helmet';

const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordTyped, setPasswordTyped] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [usernameValid, setUsernameValid] = useState(true);
  const [usernameSuggestions, setUsernameSuggestions] = useState([]);
  const { setIsAuthenticated, setUserId } = useAuth();
  

  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleUsernameSelect = (selectedUsername) => {
    setValue('username', selectedUsername);
    setUsernameValid(true);
    setUsernameSuggestions([]);
    clearErrors('username');
  };

  const email = watch('email');
  const username = watch('username');

  useEffect(() => {
    const validateField = async (field, value) => {
      if (value) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/check-${field}?${field}=${value}`);
          const data = await response.json();
          if (field === 'email') {
            setEmailValid(!data.isInUse);
            if (data.isInUse) {
              setError('email', { type: 'manual', message: 'Email is already in use' });
            } else {
              clearErrors('email');
            }
          } else if (field === 'username') {
            setUsernameValid(!data.isInUse);
            if (data.isInUse) {
              setError('username', { type: 'manual', message: 'Username is already in use' });
              const suggestionResponse = await fetch(`${process.env.REACT_APP_API_URL}/suggest-username?username=${value}`);
              const suggestionData = await suggestionResponse.json();
              setUsernameSuggestions(suggestionData.suggestions);
            } else {
              clearErrors('username');
              setUsernameSuggestions([]);
            }
          }
        } catch (error) {
          console.error(`Error checking ${field}`, error);
        }
      }
    };

    validateField('email', email);
    validateField('username', username);
  }, [email, username, setError, clearErrors]);

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPasswordTyped(true);

  };


  const onSubmit = async (data) => {
    if (!emailValid || !usernameValid) {
      toast.error('Email or Username is already in use.');
      return;
    }
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include', // Include credentials for cookies
      });
     
      if (response.ok) {
        // Store the JWT in localStorage
        const responseData = await response.json();
        setIsAuthenticated(true);
        setUserId(responseData.userId);
        toast.success('Registration successful!');
        navigate('/verify-email', {state: { email: data.email } } );
        // navigate('/profile-completion', { state: { email: data.email } });
      } else {
        toast.error('Registration failed: Please try again.');
      }
    } catch (error) {
      toast.error('Error during registration: Please try again.', error);
    }
  };

  return (
    <div className={`register ${Object.keys(errors).length > 0 ? 'error' : ''}`}>
       <Helmet>
        <title>Register - Polymer Connect</title>
        <meta name="description" content="Join the Polymer Connect community. Register to connect with polymer science professionals and enthusiasts from around the world." />
        <meta name="keywords" content="polymer science, register, community, connect, networking, polymer" />
      </Helmet>
      <h2>Join the Polymer Community!</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`input-group ${errors.username ? 'error' : ''}`} data-testid="username-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            {...register('username')}
            placeholder="Enter a username"
          />
          {errors.username && <span className="error-message">{errors.username.message}</span>}
          {usernameSuggestions.length > 0 && (
            <div className="username-suggestions">
              <p>Consider using one of these available usernames:</p>
              <ul>
                {usernameSuggestions.map(suggestion => (
                  <li key={suggestion} onClick={() => handleUsernameSelect(suggestion)} className="username-suggestion">{suggestion}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={`input-group ${errors.email ? 'error' : ''}`} data-testid="email-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            {...register('email')}
            placeholder="Enter your email"
          />
          {errors.email && <span className="error-message">{errors.email.message}</span>}
        </div>
        <div className={`input-group ${errors.password ? 'error' : ''}`} data-testid="password-group">
          <label htmlFor="password">Password:</label>
          <div className="password-input">
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              {...register('password')}
              placeholder="Enter your password"
              onChange={handlePasswordChange}
              aria-label="Password"
              data-testid="password-input"
            />
            <span className="password-toggle" onClick={togglePasswordVisibility} data-testid="toggle-password-visibility">
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {errors.password && <span className="error-message">{errors.password.message}</span>}
        </div>
        <div className={`input-group ${errors.confirmPassword ? 'error' : ''}`} data-testid="confirm-password-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            {...register('confirmPassword')}
            placeholder="Confirm your password"
            data-testid="confirm-password-input"
          />
          {errors.confirmPassword && <span className="error-message">{errors.confirmPassword.message}</span>}
        </div>
        <button type="submit" disabled={isSubmitting} data-testid="submit-button">
          {isSubmitting ? 'Creating Account...' : 'Start Networking!'}
        </button>
        <p className="login-option">
          Already have an account? <Link to="/login" >Log in here</Link>.
        </p>
      </form>
    </div>
  );
};

export default Register;
