import React, { useEffect, useState } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import styles from '../style/JobDetails.module.css'; 
import { useAuth } from '../pages/AuthContext';

const JobDetails = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(false);
const [error, setError] = useState('');
const { userId, isAdmin } = useAuth();

useEffect(() => {
  const fetchJobDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/${jobId}`);
      setJobDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching job details:', error);
      setError('Failed to load job details. Please try again later.');
      setLoading(false);
    }
  };

  fetchJobDetails();
}, [jobId]);

  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  };

  const handleDeleteJob = async () => {
    if (!window.confirm('Are you sure you want to delete this job?')) return;
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/jobs/${jobId}`, { withCredentials: true });
      navigate('/job-list'); // Redirect to the jobs list or another appropriate page
    } catch (error) {
      console.error('Error deleting job:', error);
      // Optionally set an error state to display a message to the user
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!jobDetails) return <div>Job details not available.</div>;


  return (
    <div className={styles.jobDetailsContainer}>
       <h1>{jobDetails.title}</h1>
      {jobDetails.company_name && (
        <div className={styles.companyCard}>
         <img src={jobDetails.company_logo} alt={`${jobDetails.company_name} Logo`} className={styles.companyLogo} />
          <h2>{jobDetails.company_name}</h2>
          {jobDetails.company_website && <p>Website: <a href={formatUrl(jobDetails.company_website)} target="_blank" rel="noopener noreferrer">{jobDetails.company_website}</a></p>}
          {jobDetails.company_size && <p>Size: {jobDetails.company_size}</p>}
          {jobDetails.company_city && <p>City: {jobDetails.company_city}</p>}
          {jobDetails.company_country && <p>Country: {jobDetails.company_country}</p>}
          {jobDetails.company_linkedin && <p>LinkedIn: <a href={formatUrl(jobDetails.company_linkedin)} target="_blank" rel="noopener noreferrer">Profile</a></p>}
        </div>
      )}
      <h3>Location: {jobDetails.location}</h3>
      <h3>Type: {jobDetails.type}</h3>
      <h3>Deadline: {new Date(jobDetails.deadline).toLocaleDateString()}</h3>
      <div className={styles.jobDescription}>
        <h4>Description:</h4>
        <p>{jobDetails.description}</p>
      </div>
      <div className={styles.qualifications}>
        <h4>Qualifications:</h4>
        <p>{jobDetails.qualifications}</p>
      </div>
      {jobDetails.apply_link ? (
  <a href={jobDetails.apply_link} target="_blank" rel="noopener noreferrer" className={styles.applyButton}>Apply Now</a>
) : jobDetails.contact_instructions ? (
  <div className={styles.applyInstruction}>{jobDetails.contact_instructions}</div>
) : (
  <div className={styles.noApplyLink}>No apply link provided. Please contact the company directly.</div>
)}
 {(isAdmin || jobDetails.creator_user_id === userId) && (
        <button onClick={handleDeleteJob} className={styles.deleteButton}>
          Delete Job
        </button>
      )}

    </div>
  );
};

export default JobDetails;
