import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styles from '../style/ChatInterface.module.css';

const Message = ({ message, isUserMessage, onDelete, formatTimestamp }) => {
  const [swipeStyle, setSwipeStyle] = useState({});

  const swipeHandlers = useSwipeable({
    onSwiping: (eventData) => {
      if (isUserMessage && eventData.dir === 'Left') {
        setSwipeStyle({ transform: `translateX(${eventData.deltaX}px)` });
      }
    },
    onSwiped: (eventData) => {
      if (isUserMessage && eventData.dir === 'Left') {
        // Call the delete function or reset swipe
        if (Math.abs(eventData.deltaX) > 50) { // threshold for swipe to delete
          onDelete(message.id);
        }
        setSwipeStyle({});
      }
    },
    trackMouse: true,
  });

  return (
    <div {...swipeHandlers} style={swipeStyle} className={`${styles.message} ${isUserMessage ? styles.outgoing : styles.incoming}`}>
      <div className={styles.messageContent}>
        {message.message}
      </div>
      <div className={styles.timestamp}>{formatTimestamp(message.timestamp)}</div>
    </div>
  );
};

export default Message;
