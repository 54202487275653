// Import images
import connectImage from '../images/connect1.png';
import jobsImage from '../images/jobs1.png';
import trendsImage from '../images/trends1.png';
import ideasImage from '../images/ideas1.png';
import pdfIcon from '../images/pdf.png';
import docIcon from '../images/doc.png';

// Export as a single object
export const images = {
  connect: connectImage,
  jobs: jobsImage,
  trends: trendsImage,
  ideas: ideasImage,
  pdf:pdfIcon,
  doc:docIcon,
};
