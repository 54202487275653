import React from 'react';
import styles from '../style/AboutPage.module.css';
import { Helmet } from 'react-helmet';

const AboutPage = () => {
  return (
    <div className={styles.aboutContainer}>
      <Helmet>
        <title>About Us - Polymer Connect</title>
        <meta name="description" content="Discover Polymer Connect, a dynamic online community dedicated to advancing polymer science through collaboration and innovation. Join us to share knowledge and drive advancements in the field." />
        <meta name="keywords" content="polymer science, polymer community, polymer research, collaboration, innovation" />
      </Helmet>
      <h1>Welcome to Polymer Connect</h1>
      <p>
        Polymer Connect is a vibrant and dynamic online platform dedicated to revolutionizing the field 
        of polymer science. Our community is built on collaboration and innovation, providing a dedicated 
        space for professionals, researchers, and enthusiasts passionate about polymers to share knowledge, 
        discuss trends, and drive advancements in the field.
      </p>

      <h2>Our Mission</h2>
      <p>
        Our mission is to create a thriving online community where members can freely exchange ideas, 
        discuss the latest research, and collaborate on projects. We believe that by focusing on the 
        collective intelligence of our global network, we can overcome challenges and push the boundaries 
        of polymer science together.
      </p>

      <h2>Join Our Forums</h2>
      <p>
        At the heart of Polymer Connect are our forums - a central hub for discussion and knowledge exchange. 
        Here, you can dive into the latest trends in polymer science, ask questions, share your insights, 
        and connect with like-minded individuals who are equally passionate about making a difference in the world.
      </p>

      <h2>Be Part of Our Community</h2>
      <ul>
        <li>Engage in discussions on a wide range of topics in polymer science.</li>
        <li>Share your research, insights, and questions with a supportive community.</li>
        <li>Collaborate with peers to drive innovation and discovery.</li>
        <li>Stay updated on the latest developments and opportunities in the field.</li>
      </ul>

      <p>
        Join Polymer Connect today and contribute to a collective effort to advance polymer science. 
        Through our forums and community discussions, we can work together to create sustainable solutions 
        and shape the future of the industry. Be part of a movement that champions open exchange, collaboration, 
        and innovation.
      </p>
    </div>
  );
};

export default AboutPage;
