import React, { useState, useEffect } from 'react';
import '../style/VerificationMessage.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Helmet } from 'react-helmet'; 

const VerificationMessage = ({ email }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [nextResendAvailable, setNextResendAvailable] = useState(Date.now());
  const navigate = useNavigate();
  const { setIsEmailVerified } = useAuth();

  // Environment variable for API URL
  const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  useEffect(() => {
    const emailSpan = document.querySelector('.email-address');
    if (emailSpan) {
      emailSpan.scrollIntoView();
    }

    const nextResend = localStorage.getItem('nextResendAvailable');
    if (nextResend && parseInt(nextResend, 10) > Date.now()) {
      setNextResendAvailable(parseInt(nextResend, 10));
    }
  }, []);

  const handleVerification = async () => {
    if (verificationCode.trim().length === 6) {
      setIsVerifying(true);
      try {
        const response = await fetch(`${apiBaseUrl}/api/verify-code`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, code: verificationCode }),
        });

        if (!response.ok) {
          throw new Error('Verification failed. Please try again.');
        }

        const data = await response.json();
        setVerificationStatus('Verification successful! Your account has been verified.');
        setIsEmailVerified(true);
        navigate('/profile-completion');
      } catch (error) {
        setVerificationStatus(error.message || 'An error occurred. Please try again.');
      } finally {
        setIsVerifying(false);
      }
    } else {
      setVerificationStatus('Please enter a valid 6-digit verification code.');
    }
  };

  const handleResendVerification = async () => {
    if (Date.now() < nextResendAvailable) {
      alert('Please wait before resending the verification code.');
      return;
    }

    setIsVerifying(true);
    try {
      const response = await fetch(`${apiBaseUrl}/api/resend-verification-code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to resend verification code.');
      }

      const data = await response.json();
      setVerificationStatus('Verification email resent successfully.');
      const cooldownPeriod = Date.now() + 3600000; // 1 hour in milliseconds
      setNextResendAvailable(cooldownPeriod);
      localStorage.setItem('nextResendAvailable', cooldownPeriod.toString());
    } catch (error) {
      setVerificationStatus(error.message || 'An error occurred. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const isResendDisabled = Date.now() < nextResendAvailable;

  return (
    <div className="verification-message-container">
       <Helmet>
        <title>Email Verification - Polymer Connect</title>
        <meta name="description" content="Enter the verification code sent to your email to complete the registration process on Polymer Connect." />
      </Helmet>
      <div className="verification-message">
        <h2>Verify Your Email</h2>
        <p>
          We've sent a verification code to <span className="email-address">{email}</span>. Please enter the verification code below to complete your registration.
        </p>

        <input
          type="text"
          className="verification-code-input"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="6-digit code"
          maxLength="6"
        />
        {isVerifying ? <p>Verifying...</p> : <button className="verify-button" onClick={handleVerification} disabled={isVerifying}>
            Verify Code
          </button>}
        
        <p className="verification-status">{verificationStatus}</p>

        <p>If you didn't receive the code, please check your spam folder or <button onClick={handleResendVerification} disabled={isResendDisabled || isVerifying} className="resend-link">
          Click here
        </button>
        to resend the verification email.</p>
        
        {isResendDisabled && <p className="cooldown-message">You can resend the verification email in {Math.ceil((nextResendAvailable - Date.now()) / 60000)} minutes.</p>}
        
        <p>Need help? Contact us at <a className="support-email" href="mailto:support@example.com">polymercommunity@gmail.com</a>.</p>
      </div>  
    </div>
  );
};

export default VerificationMessage;