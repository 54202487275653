import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserProfileForm from './UserProfileForm';
import styles from '../style/Profile.module.css'; // Ensure this path is correct
import { FaUserCircle, FaRegEdit, FaLinkedin, FaResearchgate, FaTrash, FaCheckCircle } from 'react-icons/fa'; // Additional icons for styling
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
// import CorporateEmailVerificationForm from './CorporateEmailVerificationForm';
import { Helmet } from 'react-helmet';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [passwordForDeletion, setPasswordForDeletion] = useState('');
  const navigate = useNavigate();
  const { logout } = useAuth();
  // const [showEmailVerification, setShowEmailVerification] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile/myprofile`, { withCredentials: true });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchData();
  }, []);

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleDeleteAccount = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-account`, { data: { password: passwordForDeletion }, withCredentials: true });
      alert('Your account has been successfully deleted.');
      logout();
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete the account. Please check your password and try again.');
    }
  };

  // const toggleEmailVerification = () => {
  //   setShowEmailVerification(!showEmailVerification);
  // };

  if (!userData) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.profileContainer}>
       <Helmet>
        <title>My Profile - Polymer Connect</title>
        <meta name="description" content="View and edit your profile on Polymer Connect to keep your professional information up to date for the polymer science community." />
      </Helmet>
      {!isEditing ? (
        <div className={styles.profileView}>
          <h2>Profile</h2>
          {userData.profile_photo_url ? (
            <img src={userData.profile_photo_url} alt="Profile" className={styles.profileImage} />
          ) : (
            <FaUserCircle size="100" className={styles.defaultProfileIcon} />
          )}
          <div className={styles.infoSection}>
            <h3 className={styles.profileName}>{userData.fullname}{userData.verified && <FaCheckCircle className={styles.verifiedIcon} />}</h3>
            <div className={styles.detailSection}>
              {/* User Information */}
              <div className={styles.detailItem}><strong>Username:</strong> {userData.username}</div>
              <div className={styles.detailItem}><strong>Email:</strong> {userData.email}</div>
              <div className={styles.detailItem}><strong>Role:</strong> {userData.role}</div>
              <div className={styles.detailItem}><strong>Expertise:</strong> {userData.expertise}</div>
              {/* Academic and Professional Details */}
              <div className={styles.detailItem}><strong>Academic Credentials:</strong> {userData.academiccredentials}</div>
              <div className={styles.detailItem}><strong>Research Publications:</strong> {userData.researchpublications}</div>
              <div className={styles.detailItem}><strong>Professional Achievements:</strong> {userData.professionalAchievements}</div>
              {/* Portfolio and Interests */}
              <div className={styles.detailItem}><strong>Interest Areas:</strong> {userData.interestAreas}</div>
            </div>
            {/* Social Links */}
            <div className={styles.socialLinks}>
              {userData.linkedInProfile && (
                <a href={`https://${userData.linkedInProfile}`} target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                  <FaLinkedin /> LinkedIn
                </a>
              )}
              {userData.researchGateProfile && (
                <a href={`https://${userData.researchGateProfile}`} target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                  <FaResearchgate /> ResearchGate
                </a>
              )}
            </div>
          </div>
          {/* <button onClick={toggleEmailVerification} className={styles.verifyButton}><FaCheckCircle /> Get Verified</button>
          {showEmailVerification && <CorporateEmailVerificationForm toggleEmailVerification={toggleEmailVerification} />} */}
            <div className={styles.buttonContainer}>
          <button onClick={handleToggleEdit} className={styles.editButton}><FaRegEdit /> Edit Profile</button>
          <button onClick={() => setShowDeleteConfirmation(true)} className={styles.deleteButton}><FaTrash /> Delete Account</button>
          </div>
          
          {showDeleteConfirmation && (
            <div className={styles.deleteConfirmation}>
              <p>Please enter your password to confirm account deletion:</p>
              <input
                type="password"
                value={passwordForDeletion}
                onChange={(e) => setPasswordForDeletion(e.target.value)}
                className={styles.passwordInput}
              />
              <button onClick={handleDeleteAccount} className={styles.confirmDeleteButton}>Confirm Delete</button>
              <button onClick={() => setShowDeleteConfirmation(false)} className={styles.cancelButton}>Cancel</button>
            </div>
          )}
        </div>
      ) : (
        <UserProfileForm userData={userData} handleEditToggle={handleToggleEdit} />
      )}
    </div>
  );
};

export default Profile;
