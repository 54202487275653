import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure you've installed axios: npm install axios
import styles from '../style/ProjectSubmissionForm.module.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ProjectSubmissionForm = () => {
  const [projectDetails, setProjectDetails] = useState({
    title: '',
    researchArea: '',
    objectives: '',
    methodology: '',
    expertiseRequired: '',
    equipmentNeeded: '',
    duration: '',
    collaborationTerms: '',
    funding: '',
  });
  const [researchAreas, setResearchAreas] = useState([]); // State to hold fetched research areas
  const [submissionStatus, setSubmissionStatus] = useState('');
  const navigate = useNavigate();
  const [hasAcknowledgedIpPolicy, setHasAcknowledgedIpPolicy] = useState(false);


  useEffect(() => {
    const fetchResearchAreas = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/research-areas`); // Adjust the URL as needed
            setResearchAreas(response.data);
        } catch (error) {
            console.error('Failed to fetch research areas:', error);
        }
    };

    fetchResearchAreas();
}, []);// Empty dependency array means this effect runs once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, researchArea, objectives, methodology, expertiseRequired, duration, collaborationTerms } = projectDetails;
    if (!title || !researchArea || !objectives || !methodology || !expertiseRequired || !duration || !collaborationTerms || !hasAcknowledgedIpPolicy) {
      toast.error('Please complete all required fields and agree to the Intellectual Property Policy.');
      return;
    }
    
    try {   
        // No need for Authorization header here as cookies are automatically sent
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/project-proposals`, projectDetails, {
            withCredentials: true, // Important for including cookies in cross-origin requests
        });
        console.log('Submission successful:', response.data);
        navigate('/join-slack');
        toast.success('Submission successful');
        setSubmissionStatus('Submission successful');
        // Optionally reset form or redirect user
    } catch (error) {
        console.error('Submission failed:', error.response ? error.response.data : error);
        toast.success('Submission failed');
        setSubmissionStatus('Submission failed. Please try again.');
    }
};
  return (
    <form onSubmit={handleSubmit} className={styles.formContainer}>
      <h2 className={styles.formTitle}>Submit Polymer Science Project Proposal</h2>
      
      <label htmlFor="title" className={styles.inputLabel}>Project Title <span className={styles.requiredAsterisk}>*</span></label>
      <input
        type="text"
        id="title"
        name="title"
        value={projectDetails.title}
        onChange={handleChange}
        placeholder="Enter a concise and descriptive title for your project"
        required
        className={styles.inputField}
      />
      
      <label htmlFor="researchArea" className={styles.inputLabel}>Research Area <span className={styles.requiredAsterisk}>*</span></label>
      <select
        id="researchArea"
        name="researchArea"
        value={projectDetails.researchArea}
        onChange={handleChange}
        required
        className={styles.selectField}
      >
        <option value="">-- Select an area of research --</option>
        {researchAreas.map(area => (
          <option key={area.id} value={area.id}>{area.name}</option>
        ))}
      </select>
      
      <label htmlFor="objectives" className={styles.inputLabel}>Project Objectives <span className={styles.requiredAsterisk}>*</span></label>
      <textarea
        id="objectives"
        name="objectives"
        value={projectDetails.objectives}
        onChange={handleChange}
        placeholder="Outline the main objectives of your project"
        required
        className={styles.textAreaField}
      />
      
      <label htmlFor="methodology" className={styles.inputLabel}>Methodology <span className={styles.requiredAsterisk}>*</span></label>
      <textarea
        id="methodology"
        name="methodology"
        value={projectDetails.methodology}
        onChange={handleChange}
        placeholder="Describe the methods and approaches to be used in your research"
        required
        className={styles.textAreaField}
      />
      
      <label htmlFor="expertiseRequired" className={styles.inputLabel}>Required Expertise <span className={styles.requiredAsterisk}>*</span></label>
      <textarea
        id="expertiseRequired"
        name="expertiseRequired"
        value={projectDetails.expertiseRequired}
        onChange={handleChange}
        placeholder="Specify the expertise needed from collaborators"
        required
        className={styles.textAreaField}
      />
      
      <label htmlFor="equipmentNeeded" className={styles.inputLabel}>Equipment Needed</label>
      <input
        type="text"
        id="equipmentNeeded"
        name="equipmentNeeded"
        value={projectDetails.equipmentNeeded}
        onChange={handleChange}
        placeholder="List any specific equipment required for the project"
        className={styles.inputField}
      />
      
      <label htmlFor="duration" className={styles.inputLabel}>Project Duration <span className={styles.requiredAsterisk}>*</span></label>
      <input
        type="text"
        id="duration"
        name="duration"
        value={projectDetails.duration}
        onChange={handleChange}
        placeholder="Estimate the total duration of the project (e.g., 6 months, 2 years)"
        required
        className={styles.inputField}
      />
      
      <label htmlFor="collaborationTerms" className={styles.inputLabel}>Collaboration Terms <span className={styles.requiredAsterisk}>*</span></label>
      <textarea
        id="collaborationTerms"
        name="collaborationTerms"
        value={projectDetails.collaborationTerms}
        onChange={handleChange}
        placeholder="Detail terms for collaboration, such as data sharing, publication rights, etc."
        required
        className={styles.textAreaField}
      />
      
      <label htmlFor="funding" className={styles.inputLabel}>Funding Available</label>
      <input
        type="text"
        id="funding"
        name="funding"
        value={projectDetails.funding}
        onChange={handleChange}
        placeholder="Specify any funding or resources available for the project"
        className={styles.inputField}
      />
     <label className={styles.checkboxLabel}>
  <input
    type="checkbox"
    checked={hasAcknowledgedIpPolicy}
    onChange={e => setHasAcknowledgedIpPolicy(e.target.checked)}
    className={styles.hiddenCheckbox} // Hide the actual checkbox
    
  />
  <span className={styles.customCheckbox}></span> {/* Custom checkbox */}
  I agree to the <a href="/ip-policy" target="_blank" rel="noopener noreferrer">Intellectual Property Policy</a>.
</label>
      
      {/* Consider adding any remaining input fields with appropriate styles. */}
      {submissionStatus && <p>{submissionStatus}</p>}
      <button type="submit" className={styles.submitButton}>
  Submit Proposal
</button>
    </form>
  );
  
  
};

export default ProjectSubmissionForm;
