import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSocket } from './SocketContext'; // Import the global socket context
import { useAuth } from '../pages/AuthContext';
import { useNavigate } from 'react-router-dom';

const NotificationSystem = () => {
  const { userId, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const socket = useSocket(); // Use global socket

  useEffect(() => {
    if (socket && isAuthenticated && userId) {
      console.log("Socket used in NotificationSystem", socket);

      socket.emit('register', userId);
      console.log("Registered user ID:", userId);

      socket.on('new-message-request', (message) => {
        console.log("New message request received:", message);
        const toastId = `new-message-request-${message.requestId}`;
        if (!toast.isActive(toastId)) {
          toast.info(`New message request received`, {
            onClick: () => navigate('/chat'),
            autoClose: 5000,
            closeOnClick: true,
            toastId: toastId,
          });
        }
      });

      socket.on('message-request-accepted', (notification) => {
        console.log("Message request accepted:", notification);
        toast.success(notification.message, {
          onClick: () => navigate('/chat'),
          autoClose: 5000,
          closeOnClick: true,
        });
      });

      // Clean up socket event listeners when component unmounts
      return () => {
        socket.off('new-message-request');
        socket.off('message-request-accepted');
      };
    }
  }, [socket, userId, isAuthenticated, navigate]);

  return <ToastContainer />;
};

export default NotificationSystem;
