import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../style/ProjectProposals.module.css'; // Adjust the import path as necessary
import { toast } from 'react-toastify';
import { useAuth } from '../pages/AuthContext';

const ProjectProposals = () => {
    const [proposals, setProposals] = useState([]);
    const navigate = useNavigate();
    const { isAuthenticated, isEmailVerified } = useAuth();

    useEffect(() => {
        const fetchProposals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/project-proposals`);
                setProposals(response.data);
            } catch (error) {
                console.error('Error fetching proposals:', error);
            }
        };

        fetchProposals();
    }, []);

    const goToDetails = (proposalId) => {
        navigate(`/proposals/${proposalId}`);
    };

    const navigateToUserProfile = (userId, event) => {
        event.stopPropagation(); // Prevent triggering goToDetails
        navigate(`/userprofile/${userId}`);
    };
    const createProposal= () =>{
       
        if (!isAuthenticated) {
            toast.info('Please sign up or log in to create project proposal.', { toastId: 'signup-login-info' });
            navigate('/register');
            return; // Prevent form submission
        }
        
        if (!isEmailVerified) {
            toast.warn('Please verify your email to create project proposal.', { toastId: 'verify-email-warning' });
            return; // Prevent form submission
        }
        navigate('/project-form')
    };

    const summarizeText = (text, maxLength = 100) => {
        return text.length > maxLength ? `${text.substring(0, maxLength)}…` : text;
    };

    return (
        <div>
           <div className={styles.notificationBanner}>
                <p>
                    Tip: Completing your profile with your full name, expertise, academic credentials, publications, and LinkedIn profile makes it easier for others to collaborate with you. <a href="profile">Complete your profile now.</a>
                </p>
            </div>
            <h2>Project Proposals</h2>
            <button className={styles.createProposalButton} onClick={createProposal}>
                Create Proposal
            </button>
            {proposals.map((proposal) => (
                <div key={proposal.id} className={styles.proposalCard} onClick={() => goToDetails(proposal.id)}>
                    <div className={styles.userDetails}>
                        {proposal.profile_photo_url && (
                            <img src={`${process.env.REACT_APP_API_URL}/${proposal.profile_photo_url}`} alt="Profile" className={styles.profilePic} />
                        )}
                        <span className={styles.userName} onClick={(e) => navigateToUserProfile(proposal.user_id, e)}>
                            {proposal.username}
                        </span>
                    </div>
                    <h3 className={styles.detailText}>{proposal.title}</h3>
                    <p className={styles.detailText}><strong>Research Area:</strong> {proposal.research_area_name}</p>
                    <p className={styles.detailText}><strong>Objectives:</strong> {summarizeText(proposal.objectives)}</p>
                    <p className={styles.detailText}><strong>Duration:</strong> {proposal.duration}</p>
                </div>
            ))}
        </div>
    );
};

export default ProjectProposals;
