import React, { useState, useEffect } from 'react';
import styles from '../style/BackToTopButton.module.css'; // Make sure to create and import the CSS module

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to toggle visibility
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          className={styles.backToTopButton}
          onClick={scrollToTop}
        >
          ↑ Top
        </button>
      )}
    </>
  );
};

export default BackToTopButton;
