import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../style/ThreadsList.module.css'; // Update your CSS file accordingly
import { FaRegCommentDots, FaRegClock } from 'react-icons/fa';

const ThreadsList = () => {
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTopThreads = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/threads?limit=3`);
                setThreads(response.data.threads || []);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching threads:', error);
                setLoading(false);
            }
        };

        fetchTopThreads();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Use a library like date-fns or moment.js for more complex formatting
        return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className={styles.threadsList}>
            {threads.length > 0 ? (
                threads.map(thread => (
                    <div key={thread.id} className={styles.threadCard} onClick={() => navigate(`/forum/${thread.id}`)}>
                        <h3 className={styles.threadTitle}>{thread.title}</h3>
                        <p className={styles.threadContent}>{thread.content}</p>
                        <div className={styles.threadFooter}>
                            <FaRegClock className={styles.icon} />
                            <span className={styles.timestamp}>{formatDate(thread.created_at)}</span>
                            <FaRegCommentDots className={styles.icon} />
                        </div>
                    </div>
                ))
            ) : (
                <p>No threads to display.</p>
            )}
            <button onClick={() => navigate('/forum')} className="btn btn-primary mt-3">
                Explore Forum
            </button>
        </div>
    );
};

export default ThreadsList;
