import React, { useState, useEffect } from 'react';
import styles from '../style/PolymerScienceNews.module.css';
import { Helmet } from 'react-helmet';

const PolymerScienceNews = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const cacheKey = 'polymerScienceNews';
    const newsCache = localStorage.getItem(cacheKey);
    const currentTime = new Date().getTime();

    // Attempt to fetch news from local storage first
    if (newsCache) {
      const { expiry, data } = JSON.parse(newsCache);

      if (currentTime < expiry) {
        console.log('Serving from localStorage');
        setNews(data);
        return;
      } else {
        console.log('Cache expired');
        localStorage.removeItem(cacheKey);
      }
    }

    // Fetch the news from the API if not found in cache or cache is expired
    const fetchNews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/news/polymer-science`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Cache the new data with a new expiry
        const expiry = new Date().getTime() + 3600000; // 1 hour expiry
        localStorage.setItem(cacheKey, JSON.stringify({ expiry, data }));

        setNews(data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className={styles.newsContainer}>
         <Helmet>
        <meta name="description" content="Stay updated with the latest developments and research in polymer science." />
        <meta name="keywords" content="polymer science, polymer research, materials science, chemistry news" />
      </Helmet>
      <ul>
        {news.map((article, index) => (
          <li key={index} className={styles.articleItem}>
            {article.image && <img src={article.image} alt="Article visual" className={styles.articleImage} />}
            <a href={article.url} target="_blank" rel="noopener noreferrer" className={styles.articleTitle}>
              <h3>{article.title}</h3>
            </a>
            <p className={styles.articleDescription}>{article.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PolymerScienceNews;
