import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import styles from '../style/CompanyRegistrationForm.module.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CompanyRegistrationForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [countries, setCountries] = useState([]);
  const [filePreview, setFilePreview] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryData = response.data.map(country => country.name.common);
        setCountries(countryData.sort());
      })
      .catch(error => {
        console.error('Failed to fetch countries:', error);
        toast.error('Failed to load country list.');
      });
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setFilePreview(null);
      return;
    }

    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      toast.error('Only JPG and PNG files are allowed.');
      return;
    }

    if (file.size > 5242880) { // 5MB
      toast.error('File size should not exceed 5MB.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    if (data.logo.length > 0) {
      formData.append('logo', data.logo[0]);
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/companies`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Company registered successfully!');
      navigate('/company-list');
    } catch (error) {
      console.error('Failed to register company:', error);
      toast.error('Failed to register company.');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
      <div className={styles.formGroup}>
        <label htmlFor="name" className={styles.label}>Company Name:</label>
        <input id="name" {...register('name', { required: true })} className={styles.input}/>
        {errors.name && <span className={styles.errorMsg}>This field is required</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="website" className={styles.label}>Website:</label>
        <input id="website" {...register('website')} className={styles.input}/>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="size" className={styles.label}>Company Size:</label>
        <select id="size" {...register('size', { required: true })} className={styles.select}>
          <option value="">Select size</option>
          <option value="startup">1-50 employees</option>
          <option value="small">51-200 employees</option>
          <option value="medium">201-1,000 employees</option>
          <option value="large">1,001-10,000 employees</option>
          <option value="multinational">10,000+ employees</option>
        </select>
        {errors.size && <span className={styles.errorMsg}>This field is required</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="city" className={styles.label}>City:</label>
        <input id="city" {...register('city')} className={styles.input}/>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="country" className={styles.label}>Country:</label>
        <select id="country" {...register('country', { required: true })} className={styles.select}>
          <option value="">Select country</option>
          {countries.map((name, index) => (
            <option key={index} value={name}>{name}</option>
          ))}
        </select>
        {errors.country && <span className={styles.errorMsg}>This field is required</span>}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="linkedinPage" className={styles.label}>LinkedIn Page:</label>
        <input id="linkedinPage" {...register('linkedin_page')} className={styles.input}/>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="logo" className={styles.label}>Logo:</label>
        <input type="file" {...register('logo')} onChange={handleFileChange} className={styles.input} />
        {filePreview && <img src={filePreview} alt="Preview" className={styles.logoPreview} />}
      </div>

      <button type="submit" className={styles.button}>Register Company</button>
    </form>
  );
};

export default CompanyRegistrationForm;
