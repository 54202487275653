  import React, { useEffect, useRef, useState } from 'react';
  import { FaPaperPlane, FaArrowLeft  } from 'react-icons/fa';
  import Message from './Message';
  import styles from '../style/ChatInterface.module.css';


  const ChatInterface = React.memo(({ messages, onSendMessage, onDeleteMessage, loggedInUserId, selectedUser, onBack, isMobileView  }) => {
    const [newMessage, setNewMessage] = useState('');
    const messagesContainerRef = useRef(null);

    useEffect(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, [messages]);

    const handleSendMessage = (e) => {
      e.preventDefault();
      if (!newMessage.trim()) return;
      onSendMessage(newMessage);
      setNewMessage('');
    };

    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (timestamp) => {
      const messageDate = new Date(timestamp);
      const today = new Date();
      const yesterday = new Date(today.setDate(today.getDate() - 1));

      if (messageDate.toDateString() === new Date().toDateString()) {
        return 'Today';
      } else if (messageDate.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } else {
        return messageDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      }
    };

    const isDifferentDay = (index) => {
      if (index === 0) return true;
      const prevDate = new Date(messages[index - 1].timestamp).toDateString();
      const currentDate = new Date(messages[index].timestamp).toDateString();
      return prevDate !== currentDate;
    };


    return (
      <div className={styles.chatInterface}>
        {selectedUser && (
          <div className={styles.chatHeader}>
            <button onClick={onBack} className={styles.backButton}>
            <FaArrowLeft /> {/* Icon for visual cue */}
          </button>
            <h4 className={styles.username}>{selectedUser.username}</h4>
          </div>
        )}
        <div className={styles.messagesContainer} ref={messagesContainerRef}>
          {messages.slice().reverse().map((message) => {
            return (
              <React.Fragment key={message.id}>
                {isDifferentDay(messages.indexOf(message)) && (
                  <div className={styles.dateSeparator}>
                    {formatDate(message.timestamp)}
                  </div>
                )}
                <Message
                  message={message}
                  isUserMessage={message.sender_id === loggedInUserId}
                  onDelete={onDeleteMessage}
                  formatTimestamp={formatTimestamp}
                />
              </React.Fragment>
            );
          })}
        </div>
        <form onSubmit={handleSendMessage} className={styles.inputArea} aria-label="Send a message">
    <div className={styles.inputWrapper}>
      <textarea  
        type="text" 
        value={newMessage} 
        onChange={(e) => setNewMessage(e.target.value)} 
        placeholder="Type a message..." 
        className={styles.messageInput} 
        aria-label="Message input"
        onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage(e)}
      />
      <button type="submit" className={styles.sendButton} aria-label="Send message">
        <FaPaperPlane className={styles.sendIcon} />
      </button>
    </div>
  </form>

      </div>
    );
  });

  export default ChatInterface;
