// SlackJoinInstructions.js
import React from 'react';
import styles from '../style/SlackJoinInstructions.module.css';

const SlackJoinInstructions = () => {
  return (
    <div className={styles.instructionsContainer}>
      <h2>Welcome to Our Collaboration Community!</h2>
      <p>To ensure a smooth collaboration process, please join our Slack workspace using the <strong>same email address</strong> you registered with.</p>
      <a href="https://join.slack.com/t/polymerconnect/shared_invite/zt-2e8zviai8-59t8Ldu_VD8oAuGDiRGwTQ" target="_blank" rel="noopener noreferrer" className={styles.joinSlackButton}>Join Our Slack Workspace</a>
      <p>If you have any questions or need assistance, feel free to contact our support team.</p>
    </div>
  );
};

export default SlackJoinInstructions;
