import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from '../style/JobPostOrCompanyRegister.module.css'; // Ensure CSS module exists

const JobPostOrCompanyRegister = () => {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/user/companies`, {
      withCredentials: true,
    })
    .then(response => {
      setCompanies(response.data); // Always set the companies, even if the array is empty
    })
    .catch(error => {
      console.error('Failed to fetch companies:', error);
      toast.error('Failed to load companies.');
    });
  }, [navigate]);

  const handleCompanySelect = (companyId) => {
    // Navigate to job posting form with the selected company ID
    navigate(`/post-job?companyId=${companyId}`);
  };

  const handleRegisterNewCompany = () => {
    // Navigate to company registration form
    navigate('/company-form');
  };

  return (
    <div className={styles.container}>
      <h2>Select a Company to Post a Job</h2>
      {companies.length > 0 ? (
        <ul className={styles.companyList}>
          {companies.map((company) => (
            <li key={company.company_id} className={styles.companyItem}>
              <button onClick={() => handleCompanySelect(company.company_id)} className={styles.selectButton}>
                {company.name}
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No companies found. You can register a new company.</p>
      )}
      <button onClick={handleRegisterNewCompany} className={styles.registerButton}>
        Register a New Company
      </button>
    </div>
  );
};

export default JobPostOrCompanyRegister;
