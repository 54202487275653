import {React, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; 
import './App.css';
import { AuthProvider, useAuth } from './pages/AuthContext';
import Header from './components/Header';
import Home from './pages/Home';
import Forum from './pages/Forum';
import Login from './pages/Login';
import Register from './pages/Register';
import ContactUs from './pages/ContactUs';
import ProfileCompletionPage from './pages/ProfileCompletionForm';
import WelcomeDashboardPage from './pages/WelcomeDashboardPage';
import CategoryCards from './pages/CategoryCards';
import CreatePost from './pages/CreatePost';
import ThreadDetail from './pages/ThreadDetail';
import Profile from './pages/Profile';
import UserProfile from './pages/UserProfile';
import UserProfileForm from './pages/UserProfileForm';
import ChatComponent from './pages/ChatComponent';
import NotificationSystem from './pages/NotificationSystem';
import ChatInterface from './pages/ChatInterface';
import ProjectSubmissionForm from './pages/ProjectSubmissionForm';
import ProjectProposals from './pages/ProjectProposals';
import ProposalDetail from './pages/ProposalDetail';
import Thread from './pages/Thread';
import MyThreads from './pages/MyThreads';
import IntellectualPropertyPolicy from './pages/IntellectualPropertyPolicy';
import UserVerificationPage from './pages/UserVerificationPage';
import JobPostForm from './pages/JobPostForm';
import CompanyRegistrationForm from './pages/CompanyRegistrationForm';
import JobPostOrCompanyRegister from './pages/JobPostOrCompanyRegister';
import JobDetails from './pages/JobDetails';
import JobPostings from './pages/JobPostings';
import setupCsrfToken from './utils/setupCsrfToken';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import AboutPage from './pages/AboutPage';
import EmployersPage from './pages/EmployersPage';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SlackJoinInstructions from './pages/SlackJoinInstructions';
import PolymerScienceNews from './pages/PolymerScienceNews'; 
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';

// ... other imports

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppRoutes = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<UserVerificationPage />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/category/:categoryId" element={<Thread />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/profile-completion" element={<ProtectedRoute><ProfileCompletionPage/></ProtectedRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><WelcomeDashboardPage/></ProtectedRoute>}/>
        <Route path="/category" element={<CategoryCards/>} />
        <Route path="/create-post" element={<CreatePost/>} />
        <Route path="/thread/:threadId" element={<ThreadDetail/>} />
        <Route path="/forum/:threadId" element={<ThreadDetail />} />
        <Route path="/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>} />
        <Route path="/edit-profile" element={<ProtectedRoute><UserProfileForm/></ProtectedRoute>} />
        <Route path="/userprofile/:userId" element={<UserProfile/>} />
        <Route path="/chat" element={<ProtectedRoute><ChatComponent/></ProtectedRoute>} />
        <Route path="/chat-interface" element={<ProtectedRoute><ChatInterface/></ProtectedRoute>} />
        <Route path="/my-threads" element={<ProtectedRoute><MyThreads/></ProtectedRoute>} />
        <Route path="/project-form" element={<ProjectSubmissionForm/>} />
        <Route path="/join-slack" element={<SlackJoinInstructions />} />
        <Route path="/project-page" element={<ProjectProposals/>} />
        <Route path="/proposals/:proposalId" element={<ProposalDetail/>} />
        <Route path="/ip-policy" element={<IntellectualPropertyPolicy />} />
        <Route path="/post-job" element={<JobPostForm/>} />
        <Route path="/company-form" element={<CompanyRegistrationForm/>} />
        <Route path="/company-list" element={<ProtectedRoute><JobPostOrCompanyRegister/></ProtectedRoute>} />
        <Route path="/job-list" element={<JobPostings/>} />
        <Route path="/job-details/:jobId" element={<JobDetails />} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/reset-password/:token" element={<ResetPassword/>} />
        <Route path="/employer-page" element={<EmployersPage/>} />
        <Route path="/polymer-news" element={<PolymerScienceNews/>} />
        <Route path="blog" element={<BlogList />} />
        <Route path="/blogs/:id" element={<BlogDetail />} />
        {/* Other routes for additional pages */}
      </Routes>
      <NotificationSystem />
      <Footer />
    </div>
    
  );
};

const App = () => {
  useEffect(() => {
    setupCsrfToken(); // Fetch and set CSRF token globally
  }, []);
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
};

export default App;