import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../style/Dashboard.module.css';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet'; 

const Dashboard = () => {
  const [user, setUser] = useState({ username: '', email: '', emailVerified: false });
  const [isLoading, setIsLoading] = useState(true); // Added for better UX during data fetch
  const [isSendingVerification, setIsSendingVerification] = useState(false);
  const navigate = useNavigate();
  const { isEmailVerified } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-data`, { withCredentials: true });
        const emailVerificationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/check-email-verification`, { withCredentials: true });
        
        setUser({
          ...response.data,
          emailVerified: emailVerificationResponse.data.emailVerified
        });
      } catch (error) {
        console.error('Error fetching user data');
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const navigateToSection = (section) => navigate(`/${section}`);

  const sendVerificationEmailAndNavigate = async () => {
    setIsSendingVerification(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/send-verification-code`, { email: user.email });
      toast.success("Verification email sent successfully. Please check your inbox.");
      navigate('/verify-email', { state: { email: user.email } });
    } catch (error) {
      console.error('Error sending verification email');
      toast.error(error.response?.status === 429 ? "Too many requests. Please try again later." : "Failed to send verification email. Please try again.");
    } finally {
      setIsSendingVerification(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.dashboardContainer}>
       <Helmet>
        <title>Dashboard - Polymer Science Community</title>
        <meta name="description" content="Your personalized dashboard in the Polymer Science Community platform. Access forums, connect with experts, and explore the latest in polymer science." />
      </Helmet>
      {!user.emailVerified && (
        <div className={styles.emailVerificationBanner}>
          <p>Please <button onClick={sendVerificationEmailAndNavigate} disabled={isSendingVerification} className={`${styles.linkLikeButton} ${isSendingVerification ? styles.disabledButton : ''}`}>verify your email</button> for full access.</p>
        </div>
      )}
      <div className={styles.dashboardHeader}>
        <h2>Welcome to the Polymer Science Community, {user.username}!</h2>
        <p>Connect with experts, peers, and fellow enthusiasts.</p>
      </div>
      <div className={styles.dashboardCta}>
        {/* Simplified the buttons for brevity */}
        <button onClick={() => navigateToSection('forum')}>Community Forums</button>
        <button onClick={() => navigateToSection('profile')}>Profile</button>
        <button onClick={() => navigateToSection('chat')}>Chat</button>
        {/* <button onClick={() => navigateToSection('project-page')}>Project</button> */}
        <button onClick={() => navigateToSection('my-threads')}>My Posts</button>
        <button onClick={() => navigateToSection('polymer-news')}>News</button>
        {/* <button onClick={() => navigateToSection('job-list')}>Jobs</button>
        <button onClick={() => navigateToSection('company-list')}>Post Job</button> */}
        {/* Add more buttons as needed */}
      </div>
    </div>
  );
};

export default Dashboard;
