import React from 'react';
import styles from '../style/ContactUs.module.css'; 
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Contact Us - Polymer Connect</title>
        <meta name="description" content="Reach out to the Polymer Connect team with any questions, suggestions, or feedback. We're here to support the polymer science community." />
      </Helmet>
      <h1 className={styles.title}>Contact Us</h1>
      <p>We love to hear from our community! If you have any questions, suggestions, or just want to say hello, feel free to reach out to us directly at:</p>
      <p><a href="mailto:polymercommunity@gmail.com" className={styles.emailLink}>polymercommunity@gmail.com</a></p>
      <p>Our team is dedicated to supporting the polymer science community, and we'll get back to you as soon as we can.</p>
    </div>
  );
};

export default ContactUs;
