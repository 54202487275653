import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../pages/AuthContext';
import styles from '../style/MyThreads.module.css'; // Adjust the path as necessary
import { Helmet } from 'react-helmet';

const MyThreads = () => {
    const [threads, setThreads] = useState([]);
    const { userId } = useAuth();

    useEffect(() => {
        if (!userId) return;

        const fetchUserThreads = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/threads/user/${userId}`, {
                    withCredentials: true
                });
                setThreads(response.data);
            } catch (err) {
                console.error('Failed to fetch user threads:', err);
            }
        };

        fetchUserThreads();
    }, [userId]);

    return (
        <div className={styles.container}>
            <Helmet>
                <title>My Threads - Polymer Connect</title>
                <meta name="description" content="View and manage your threads on Polymer Connect. Engage with the community on topics you've started." />
            </Helmet>
            <h2 className={styles.header}>My Threads</h2>
            {threads.length > 0 ? (
                <ul className={styles.threadList}>
                    {threads.map((thread) => (
                        <li key={thread.id} className={styles.threadItem}>
                            <Link to={`/thread/${thread.id}`} className={styles.threadLink}>
                                {thread.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className={styles.noThreadsMessage}>You have not created any threads yet.</p>
            )}
        </div>
    );
};

export default MyThreads;
