import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../style/BlogList.module.css';

const BlogList = ({ limit }) => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    setIsLoading(true); // Set loading to true when component mounts or limit changes
    axios.get(`${process.env.REACT_APP_API_URL}/api/blogs`)
      .then(response => {
        const limitedBlogs = limit ? response.data.slice(0, limit) : response.data;
        setBlogs(limitedBlogs);
        setIsLoading(false); // Set loading to false after fetching data
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
        setIsLoading(false); // Ensure loading is set to false even if there is an error
      });
  }, [limit]);

  return (
    <div className={styles.blogList}>
      <Helmet>
        <meta name="description" content="Explore the latest insights and research trends in polymer science through our blog posts." />
        <meta name="keywords" content="polymer science, polymer research, biodegradable polymers, smart polymers, polymer nanocomposites" />
      </Helmet>

      <h1>Blog Posts</h1>
      {isLoading ? (
        <p>Loading...</p> // Display this while the data is still loading
      ) : blogs.length > 0 ? (
        <ul>
          {blogs.map(blog => (
            <li key={blog.id} className={styles.blogPost}>
              <Link to={`/blogs/${blog.id}`} className={styles.blogTitle}>
                <h2>{blog.title}</h2>
              </Link>
              <p>- {blog.author}</p>
              <p>{blog.content.substring(0, 150)}...</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No blog posts found.</p> // Displayed if there are no posts after loading
      )}
    </div>
  );
};

export default BlogList;
