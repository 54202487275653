import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import styles from '../style/MessageRequestList.module.css';
import { useAuth } from '../pages/AuthContext';

const MessageRequestList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userId: loggedInUserId } = useAuth();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL);
    socket.emit('register', loggedInUserId);
  
    socket.on('message-request-status-updated', (updatedRequest) => {
      setRequests(prevRequests => prevRequests.map(request => 
        request.id === updatedRequest.id ? { ...request, ...updatedRequest } : request
      ));
    });
  
    socket.on('new-message-request', (newRequest) => {
      setRequests(prevRequests => [newRequest, ...prevRequests]);
    });
  
    fetchRequests();
  
    return () => {
      socket.off('message-request-status-updated');
      socket.off('new-message-request');
      socket.disconnect();
    };
  }, [loggedInUserId]);
  
  const fetchRequests = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/message-requests/received`, { withCredentials: true });
      setRequests(response.data);
    } catch (error) {
      setError('Error fetching message requests');
      console.error('Error fetching message requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRequest = async (requestId, action) => {
    // Provide immediate feedback in the UI
    setRequests(prevRequests => prevRequests.map(request => 
      request.id === requestId ? { ...request, status: 'processing' } : request
    ));
    
    try {
      const newStatus = action === 'accept' ? 'accepted' : 'rejected';
      await axios.put(`${process.env.REACT_APP_API_URL}/api/message-requests/${requestId}`, { status: newStatus }, { withCredentials: true });
      // No need to update the status here as it will be updated by the socket event
    } catch (error) {
      setError('Error updating request');
      console.error('Error updating request:', error);
      // Revert the UI update in case of an error
      setRequests(prevRequests => prevRequests.map(request => 
        request.id === requestId ? { ...request, status: 'pending' } : request
      ));
    }
  };

  const navigateToUserProfile = (userId) => {
    navigate(`/userprofile/${userId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.requestListContainer}>
      {requests.length > 0 ? requests.map(request => (
        <div key={request.id} className={styles.requestItem}>
          <div className={styles.userInfo} onClick={() => navigateToUserProfile(request.sender_id)}>
            {request.profile_photo_url && (
              <img src={request.profile_photo_url}alt={request.username} className={styles.requestImage} />
            )}
            <p className={styles.username}>{request.username}</p>
          </div>
          <div className={styles.requestText}>
            <p>Message: {request.message}</p>
          </div>
          <div className={styles.requestActions}>
            {request.status === 'pending' && (
              <>
                <button onClick={() => handleUpdateRequest(request.id, 'accept')} className={styles.acceptButton}>Accept</button>
                <button onClick={() => handleUpdateRequest(request.id, 'reject')} className={styles.rejectButton}>Reject</button>
              </>
            )}
          </div>
        </div>
      )) : <p>No message requests.</p>}
    </div>
  );
};

export default MessageRequestList;
