import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useAuth } from '../pages/AuthContext';
import '../style/Header.css';
import axios from 'axios';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
      logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <Navbar ref={navbarRef} className="header" expand="lg" expanded={expanded}>
      <Navbar.Brand className="logo">
        <Link to={isAuthenticated ? "/dashboard" : "/"} onClick={() => setExpanded(false)}>The Polymer Connect</Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto nav-links">
          <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)} active={isActive('/about')}>About Us</Nav.Link>
          <Nav.Link as={Link} to="/contact" onClick={() => setExpanded(false)} active={isActive('/contact')}>Contact Us</Nav.Link>
          <Nav.Link as={Link} to="/forum" onClick={() => setExpanded(false)} active={isActive('/forum')}>Forum</Nav.Link>
          <Nav.Link as={Link} to="/blog" onClick={() => setExpanded(false)} active={isActive('/blog')}>Blog</Nav.Link>
          {isAuthenticated ? (
            <>
              <Nav.Link as={Link} to="/profile" onClick={() => setExpanded(false)} active={isActive('/profile')}>Profile</Nav.Link>
              <Nav.Link as={Link} to="#" onClick={() => { handleLogout(); setExpanded(false); }}>Logout</Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link as={Link} to="/login" onClick={() => setExpanded(false)} active={isActive('/login')}>Login</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
