import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import MessageRequestList from './MessageRequestList';
import ChatInterface from './ChatInterface';
import styles from '../style/Chat.module.css';
import { useAuth } from '../pages/AuthContext';
import { Helmet } from 'react-helmet';


const ChatComponent = () => {
    const [contacts, setContacts] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [showRequests, setShowRequests] = useState(false);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { isAuthenticated, userId: loggedInUserId } = useAuth();
    const [selectedUser, setSelectedUser] = useState(null);
    const [socket, setSocket] = useState(null);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const [showChatInterface, setShowChatInterface] = useState(false);
   

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isAuthenticated && loggedInUserId) {
            fetchContacts();
            const newSocket = io(process.env.REACT_APP_API_URL);
            newSocket.emit('register', loggedInUserId);

            newSocket.on('new-message', (message) => {
                setMessages(prevMessages => [...prevMessages, message]);
            });
            newSocket.on('message-deleted', (deletedMessageId) => {
                setMessages(prevMessages => prevMessages.filter(message => String(message.id) !== String(deletedMessageId)));
            });

            setSocket(newSocket);

            return () => {
                newSocket.off('new-message');
                newSocket.off('message-deleted');
                newSocket.disconnect();
            };
        }
    }, [isAuthenticated, loggedInUserId]);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/${loggedInUserId}`, { withCredentials: true });
            setContacts(response.data.map(contact => ({
                ...contact,
                id: contact.contact_user_id,
                lastMessage: contact.last_message
            })));
            setLoading(false);
        } catch (error) {
            setError('Error fetching contacts');
            setLoading(false);
        }
    };

    const fetchMessages = async (userId) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/${userId}`, { withCredentials: true });
            setMessages(response.data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const selectChat = (userId) => {
        const selectedContact = contacts.find(contact => contact.id === userId);
        setSelectedUser({
            username: selectedContact.username,
            profilePhoto: `${process.env.REACT_APP_API_URL}/${selectedContact.profile_photo_url}`
        });
        setCurrentChat(userId);
        fetchMessages(userId);
        setShowChatInterface(true);
    };

    const handleSendMessage = async (messageText) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/messages/send`, {
                senderId: loggedInUserId,
                receiverId: currentChat,
                message: messageText
            }, { withCredentials: true });
            setMessages(prevMessages => [...prevMessages, response.data.data]);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}`, { withCredentials: true });
            setMessages(prevMessages => prevMessages.filter(message => String(message.id) !== String(messageId)));
            socket.emit('delete-message', messageId);
        } catch (error) {
            console.error('Error deleting message:', error);
        }
    };

    const toggleRequestsView = () => {
        setShowRequests(!showRequests);
        if (isMobileView) {
            setShowChatInterface(false);
        }
    };
    const handleBack = () => {
        setShowChatInterface(false);
      };

    return (
        <div className={styles.chatContainer}>
            <Helmet>
                <title>Chat - Polymer Connect</title>
                <meta name="description" content="Engage in private conversations with your connections on Polymer Connect. Access your messages and chat in real-time." />
            </Helmet>
            <div className={styles.contactsList}>
                <button onClick={toggleRequestsView} className={styles.toggleRequestsBtn}>
                    {showRequests ? 'Hide Requests' : 'Show Message Requests'}
                </button>
                {!showRequests && contacts.map(contact => (
                    <div key={contact.id} className={styles.contactItem} onClick={() => selectChat(contact.id)}>
                        <img src={contact.profile_photo_url} alt={contact.username} className={styles.contactImage} />
                        <div className={styles.contactDetails}>
                            <p className={styles.contactName}>{contact.username}</p>
                            <p className={styles.contactMessage}>{contact.lastMessage || ''}</p>
                        </div>
                    </div>
                ))}
                {showRequests && <MessageRequestList loggedInUserId={loggedInUserId} />}
            </div>
            {currentChat && (isMobileView ? showChatInterface : true) && (
                <ChatInterface
                    messages={messages}
                    onSendMessage={handleSendMessage}
                    onDeleteMessage={handleDeleteMessage}
                    loggedInUserId={loggedInUserId}
                    selectedUser={selectedUser}
                    onBack={handleBack}
                    isMobileView={isMobileView}
                />
            )}
            {!currentChat && !showRequests && !showChatInterface && (
                <div className={styles.noChatSelected}>Select a chat</div>
            )}
        </div>
    );
};

export default ChatComponent;
