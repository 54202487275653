import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../style/UserProfileForm.module.css';
import { useAuth } from '../pages/AuthContext';
import { FaPencilAlt } from 'react-icons/fa'; 
import { toast } from 'react-toastify';
import {useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const UserProfileForm = ({ userData }) => { // Assume userData is passed as a prop
  const [formValues, setFormValues] = useState({
    username: '',
    fullName: '',
    role: '',
    expertise: '',
    academicCredentials: '',
    researchPublications: '',
    profile_photo_url: '',
    interestAreas: '',
    professionalAchievements: '',
    linkedIn: '',
    researchGate: '',
    languageSkills: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const { userId } = useAuth();
  const navigate = useNavigate();
 
  // React immediately to userData prop changes
  useEffect(() => {
    if (userData) {
      setFormValues({
        ...formValues,
        ...userData, // This will automatically fill in all fields that match keys in userData
        fullName: userData.fullname || '', // Assuming the key in userData is 'fullname'
        academicCredentials: userData.academiccredentials || '',
        researchPublications: userData.researchpublications || '',
        profile_photo_url: userData.profile_photo_url || '',
        interestAreas: userData.interestAreas || '', // Adjust according to your userData field names
        professionalAchievements: userData.professionalAchievements || '',
        linkedIn: userData.linkedInProfile || '',
        researchGate: userData.researchGateProfile || '',
        languageSkills: userData.languageSkills || '',
      });
    }
  }, [userData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    }); 

    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };
  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newProfilePhotoUrl = URL.createObjectURL(file);
      setFormValues({
        ...formValues,
        profile_photo_url: newProfilePhotoUrl,
        profile_photo_file: file
      });
    }
  };
  const handleEditIconClick = () => {
    // Programmatically click the hidden file input
    document.getElementById('profilePhotoInput').click();
  };
  

  const validateForm = () => {
    let errors = {};
  
    if (!formValues.username.trim()) {
      errors.username = 'Username is required';
    }
    if (!formValues.role.trim()) {
      errors.role = 'Role is required';
    }
    if (!formValues.expertise.trim()) {
      errors.expertise = 'Expertise is required';
    }
  
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      setIsUpdating(true); 
      const formData = new FormData();
      
      // Append text fields to FormData
      for (const [key, value] of Object.entries(formValues)) {
        if (key !== 'profile_photo_file' && key !== 'profile_photo_url') { // Exclude file from text fields
          formData.append(key, value);
        }
      }
  
      // Append profile photo file if selected
      if (formValues.profile_photo_file) {
        formData.append('profile_photo', formValues.profile_photo_file);
      }
  
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/profile/fullprofile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        toast.success('Profile updated successfully');
        navigate('/dashboard');
      } catch (error) { 
        toast.error('Error updating profile:', error);
      }
      finally{
        setIsUpdating(false);
      }
    }
  };
  

  if (userData === null) {
    return <p>Loading...</p>;
  }
  if (!userData || !userData.username) {
    return <p>Error: User data not available or incomplete.</p>;
  }
  const placeholders = {
    academicCredentials: 'e.g., Ph.D. in Polymer Science, MSc in Chemistry',
    researchPublications: 'List your publications, patents, etc.',
    skills: 'e.g., Polymer Synthesis, Rheology, Spectroscopy',
    contactInfo: 'e.g., Email, Phone, LinkedIn profile',
  };
  return (
    <div className={styles.userProfileContainer}>
      <Helmet>
        <title>Edit Profile - Polymer Connect</title>
        <meta name="description" content="Edit your Polymer Connect profile to update your personal and professional information, ensuring your peers can connect with you effectively." />
      </Helmet>
       <div className={styles.profileHeader}>
      <h2>Complete Your Profile</h2>
        {/* Profile Photo */}
        <div className={styles.profilePhotoSection}>
        {userData && userData.profile_photo_url && (
          <>
          
          <img 
             src={formValues.profile_photo_file ? formValues.profile_photo_url : userData.profile_photo_url} 
             alt="Profile" 
             className={styles.profileImage} 
          />
          <FaPencilAlt className={styles.editIcon} onClick={handleEditIconClick} />
          <input
          id="profilePhotoInput"
          name="profile_photo"
          type="file"
          accept="image/*"
          onChange={handleProfilePhotoChange}
          style={{ display: 'none' }}
        />
          </>
        )}
      </div>
      <form onSubmit={handleSubmit} className={styles.userProfileForm}>
        {/* Basic Information Section */}
        <div className={styles.section}>
          <h3>Basic Information</h3>
          <label>Username:</label>
          <input name="username" value={formValues.username} onChange={handleChange} readOnly  />
          {formErrors.username && <span className={styles.error}>{formErrors.username}</span>}
       
          <label>Full Name:</label>
          <input name="fullName" value={formValues.fullName} onChange={handleChange} />
          {formErrors.fullName && <span>{formErrors.fullName}</span>}
        </div>
           {/* Email (non-editable) */}
        
        {/* Professional Details Section */}
        <div className={styles.section}>
          <h3>Professional Details</h3>
          <label>Role/Position:</label>
          <select name="role" value={formValues.role} onChange={handleChange}>
            {/* Role options */}
            <option value="">Select Role</option>
        <option value="Student">Student</option>
        <option value="Researcher">Researcher</option>
        <option value="Professor">Professor</option>
        <option value="Technician">Technician</option>
        <option value="Engineer">Engineer</option>
        <option value="Scientist">Scientist</option>
        <option value="Consultant">Consultant</option>
        <option value="Other">Other</option>
        {formErrors.role && <span className={styles.error}>{formErrors.role}</span>}
          </select>

          <label>Your Expertise in Polymers:</label>
          <textarea name="expertise" value={formValues.expertise} onChange={handleChange} />
          {formErrors.expertise && <span className={styles.error}>{formErrors.expertise}</span>}
        </div>

        <div className={styles.section}>
          <h3>Academic and Research</h3>
          <label>Academic Credentials:</label>
          <textarea 
            name="academicCredentials" 
            placeholder={placeholders.academicCredentials}
            value={formValues.academicCredentials} 
            onChange={handleChange} 
          />

          <label>Research Publications:</label>
          <textarea 
            name="researchPublications" 
            placeholder={placeholders.researchPublications}
            value={formValues.researchPublications} 
            onChange={handleChange} 
          />
        </div>
        <div className={styles.section}>
  <h3>Additional Information</h3>

  <label>Interest Areas in Polymer Science:</label>
  <textarea 
    name="interestAreas" 
    placeholder="Specify your areas of interest within polymer science"
    value={formValues.interestAreas} 
    onChange={handleChange} 
  />

  <label>Professional Achievements:</label>
  <textarea 
    name="professionalAchievements" 
    placeholder="List significant professional achievements or awards"
    value={formValues.professionalAchievements} 
    onChange={handleChange} 
  />

  {/* Social Media Links */}
  <label>LinkedIn Profile:</label>
  <input 
    name="linkedIn" 
    placeholder="LinkedIn profile link"
    value={formValues.linkedIn} 
    onChange={handleChange} 
  />

  <label>ResearchGate Profile:</label>
  <input 
    name="researchGate" 
    placeholder="ResearchGate profile link"
    value={formValues.researchGate} 
    onChange={handleChange} 
  />

  {/* Language Skills */}
  <label>Language Skills:</label>
  <input 
    name="languageSkills" 
    placeholder="Languages you are proficient in"
    value={formValues.languageSkills} 
    onChange={handleChange} 
  />
</div>

        <button type="submit" className={styles.submitButton} disabled={isUpdating}>{isUpdating ? 'Updating...' : 'Update Profile'}</button>
      </form>
    </div>
    </div>
  );
};

export default UserProfileForm;